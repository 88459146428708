import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import './styles.css';

// import logoCustomer from '../../assets/logo.png';
import { useSelector, useDispatch } from 'react-redux';

export default function MenuGroups() {

    const dispatch = useDispatch();

    const groups = useSelector(state => state.groups);
    const provider = useSelector(state => state.provider);
    // const products = useSelector(state => state.products);

    const history = useHistory();

    useEffect(() => {
        function loadStorage() {
            // console.log(groups);
            if (!provider.id) {
                dispatch({
                    type: 'ADD_STORAGE',
                    call: 'MENUGROUPS'
                });
                history.push('/loadstorage');
            };
        }
        loadStorage();
    }, [
        dispatch,
        history,
        provider.id
    ]);


    function handleProducts(data) {
        dispatch({
            type: 'ADD_GROUPSELECTED',
            id: data.id,
            group: data.group,
            fractioned: data.fractioned,
            fractions: data.fractions
        });
        localStorage.setItem('gselected', JSON.stringify({
            'id': data.id,
            'group': data.group,
            'fractioned': data.fractioned,
            'fractions': data.fractions
        }));

        history.push('/menuproducts');
    };

    function handleBack() {
        history.push('/');
    };

    function GroupItem({ data }) {
        return (
            <div className="divGroupItems">
                <button onClick={() => handleProducts(data)} className="groupItems">
                    <h4>{data.group}</h4>
                </button>
            </div>
        );
    };

    return (
        <div className="request-container">
            <div className="groupHeader">
                <img className="logoGroup" src={provider.logo} alt="Logo" />
                <h1>Selecione um grupo</h1>
            </div>
            <div className="groupBoddy">

                {groups.map(item => (
                    <GroupItem
                        data={item}
                        key={item.id} />
                ))}


            </div>
            <div className="containerFooter">
                <button onClick={() => handleBack()} className="groupButton" type="button">Voltar</button>
            </div>
        </div>
    );
}
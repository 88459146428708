const initialState = [];

function FlavorsReducer(state = initialState, action) {
    switch (action.type) {
        case 'ADD_FLAVORS':
            return [
                ...state,
                {
                    id: action.id,
                    idRequest: action.idRequest,
                    code: action.code,
                    description: action.description,
                    unity: action.unity,
                    amount: action.amount,
                    price: action.price,
                    totalFlavor: action.totalFlavor,
                    codeEdge: action.codeEdge,
                    edge: action.edge,
                    priceEdge: action.priceEdge,
                    totalEdge: action.totalEdge,
                    total: action.total,
                    comments: action.comments
                }];
        case 'DEL_FLAVORS':
            let s = [...state];
            return s.filter(s => s.id !== action.id);
        case 'CLEAR_FLAVORS':
            return [];
        default:
            return state;
    }
};

export default FlavorsReducer;
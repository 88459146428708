import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import firebase from '../../services/firebaseConfig';
import Switch from '@material-ui/core/Switch';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { FiArrowRight } from 'react-icons/fi';

import './styles.css';

import { useDispatch, useSelector } from 'react-redux';

export default function Checkout() {

    const history = useHistory();

    const dispatch = useDispatch();

    const provider = useSelector(state => state.provider);
    const request = useSelector(state => state.request);
    const items = useSelector(state => state.items);
    const user = useSelector(state => state.user);
    const formPayment = useSelector(state => state.formPayment);
    const neigh = useSelector(state => state.neigh);

    const [discount, setDiscount] = useState(0);

    useEffect(() => {
        function loadStorage() {
            if (!provider.id) {
                dispatch({
                    type: 'ADD_STORAGE',
                    call: 'CHECKOUT'
                });
                history.push('/loadstorage');
            } else {
                if (provider.feeDeliveryMode === 'F') {
                    dispatch({
                        type: 'TOTAL_REQUEST',
                        feeDelivery: provider.feeDelivery,
                        totalRequest: (request.totalProducts + provider.feeDelivery - discount)
                    });
                    // console.log('chamou effect a ' + request.addressDifferent.toString());
                    localStorage.setItem('request', JSON.stringify({
                        'id': request.id,
                        'idCustomer': request.idCustomer,
                        'idProvider': request.idProvider,
                        'dateRequest': request.dateRequest,
                        'totalProducts': request.totalProducts,
                        'feeDelivery': provider.feeDelivery,
                        'totalRequest': (request.totalProducts + provider.feeDelivery - discount),
                        'status': request.status, // S - enviado // P - produced // C - canceled // D - delivered 
                        'addressDifferent': request.addressDifferent,
                        'address': request.address,
                        'number': request.number,
                        'neigh': request.neigh,
                        'idNeigh': request.idNeigh,
                        'complement': request.complement,
                        'formPayment': request.formPayment
                    }));
                } else if (provider.feeDeliveryMode === 'B') {
                    const neighSel = neigh.find(neigh => neigh.id === request.idNeigh);
                    console.log(neighSel);
                    dispatch({
                        type: 'TOTAL_REQUEST',
                        feeDelivery: parseFloat(neighSel.feeDelivery),
                        totalRequest: (request.totalProducts + (parseFloat(neighSel.feeDelivery)) - discount)
                    });
                    // console.log('chamou effect b ' + request.addressDifferent.toString());
                    localStorage.setItem('request', JSON.stringify({
                        'id': request.id,
                        'idCustomer': request.idCustomer,
                        'idProvider': request.idProvider,
                        'dateRequest': request.dateRequest,
                        'totalProducts': request.totalProducts,
                        'feeDelivery': parseFloat(neighSel.feeDelivery),
                        'totalRequest': (request.totalProducts + (parseFloat(neighSel.feeDelivery)) - discount),
                        'status': request.status, // S - enviado // P - produced // C - canceled // D - delivered 
                        'addressDifferent': request.addressDifferent,
                        'address': request.address,
                        'number': request.number,
                        'neigh': request.neigh,
                        'idNeigh': request.idNeigh,
                        'complement': request.complement,
                        'formPayment': request.formPayment
                    }));
                }
            };
        }
        loadStorage();
    }, [
        discount,
        dispatch,
        history,
        neigh,
        provider.feeDelivery,
        provider.feeDeliveryMode,
        provider.id,
        request.address,
        request.addressDifferent,
        request.complement,
        request.dateRequest,
        request.formPayment,
        request.id,
        request.idCustomer,
        request.idNeigh,
        request.idProvider,
        request.neigh,
        request.status,
        request.totalProducts,
        request.number
    ]);


    function handleSendRequest(e) {
        e.preventDefault(e);
        if (request.formPayment) {
            // registra o pedido e itens
            let data = {
                id: request.id,
                idCustomer: request.idCustomer,
                idProvider: request.idProvider,
                dateRequest: request.dateRequest,
                totalProducts: request.totalProducts,
                feeDelivery: request.feeDelivery,
                totalRequest: request.totalRequest,
                status: request.status, // S - enviado // P - produced // C - canceled // D - delivered 
                address: request.address,
                number: request.number,
                neigh: request.neigh,
                idNeigh: request.idNeigh,
                complement: request.complement,
                formPayment: request.formPayment,
                items: items,
                read: false,
                dataCustomer: user,
                comeGet: request.comeGet
            };

            try {
                console.log(data);
                firebase.firestore().collection("requests").doc(data.id).set(data)
                    .then(() => {
                        history.push('/myrequests');
                        clearRequest();
                    })
                    .catch((erro) => {
                        alert('Erro ao enviar o pedido!');
                    });
            }
            catch (erro) {
                console.log(erro);
            }

        } else {
            alert('Selecione a forma de pagamento!');
        };
    };

    function clearRequest() {
        dispatch({
            type: 'CLEAR_REQUEST',
        });
        localStorage.removeItem('request');
        dispatch({
            type: 'CLEAR_ITEM',
        });
        localStorage.removeItem('items');
    };

    function handleSelectNeigh() {
        dispatch({
            type: 'ADD_CALL',
            call: 'checkout'
        });
        history.push('/neighborhood');
    };

    function handleChange(event) {
        // setState(event.target.checked);
        dispatch({
            type: 'DIFFERENT_REQUEST',
            addressDifferent: event.target.checked
        });
        // console.log('salvou local storage '+event.target.checked.toString());
        localStorage.setItem('request', JSON.stringify({
            'id': request.id,
            'idCustomer': request.idCustomer,
            'idProvider': request.idProvider,
            'dateRequest': request.dateRequest,
            'totalProducts': request.totalProducts,
            'feeDelivery': request.feeDelivery,
            'totalRequest': request.totalRequest,
            'status': request.status, // S - enviado // P - produced // C - canceled // D - delivered 
            'comeGet': request.comeGet,
            'addressDifferent': event.target.checked,
            'address': request.address,
            'number': request.number,
            'neigh': request.neigh,
            'idNeigh': request.idNeigh,
            'complement': request.complement,
            'formPayment': request.formPayment
        }));

    };

    function handleFormChange(f) {
        // console.log(f);
        dispatch({
            type: 'FORMPAYMENT_REQUEST',
            formPayment: f
        });
        localStorage.setItem('request', JSON.stringify({
            'id': request.id,
            'idCustomer': request.idCustomer,
            'idProvider': request.idProvider,
            'dateRequest': request.dateRequest,
            'totalProducts': request.totalProducts,
            'feeDelivery': request.feeDelivery,
            'totalRequest': request.totalRequest,
            'status': request.status, // S - enviado // P - produced // C - canceled // D - delivered 
            'addressDifferent': request.addressDifferent,
            'comeGet': request.comeGet,
            'address': request.address,
            'number': request.number,
            'neigh': request.neigh,
            'idNeigh': request.idNeigh,
            'complement': request.complement,
            'formPayment': f
        }));

    };

    function handleComeGet(event) {
        if (event.target.checked) {

            setDiscount(request.feeDelivery);

            dispatch({
                type: 'TOTAL_REQUEST',
                feeDelivery: request.feeDelivery,
                totalRequest: (request.totalProducts)
            });
        } else {
            setDiscount(0);

            dispatch({
                type: 'TOTAL_REQUEST',
                feeDelivery: request.feeDelivery,
                totalRequest: (request.totalProducts + request.feeDelivery)
            });
        };


        dispatch({
            type: 'COMEGET_REQUEST',
            comeGet: event.target.checked
        });
        // console.log('salvou local storage '+event.target.checked.toString());
        localStorage.setItem('request', JSON.stringify({
            'id': request.id,
            'idCustomer': request.idCustomer,
            'idProvider': request.idProvider,
            'dateRequest': request.dateRequest,
            'totalProducts': request.totalProducts,
            'feeDelivery': request.feeDelivery,
            'totalRequest': request.totalRequest,
            'status': request.status, // S - enviado // P - produced // C - canceled // D - delivered 
            'comeGet': request.comeGet,
            'addressDifferent': event.target.checked,
            'address': request.address,
            'number': request.number,
            'neigh': request.neigh,
            'idNeigh': request.idNeigh,
            'complement': request.complement,
            'formPayment': request.formPayment
        }));
    }

    return (
        <div className="checkout-container">
            <section className="sectionCheckout">
                <img className="logoCheckout" src={provider.logo} alt="Logo" />

                <form onSubmit={handleSendRequest}>
                <div className="checkHorizontal">
                        <Switch
                            checked={request.comeGet}
                            onChange={handleComeGet}
                            name="checkedComeGet"
                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                        />
                        <p>Retirar no balcão</p>
                    </div>
                    <div className="checkHorizontal">
                        <Switch
                            checked={request.addressDifferent}
                            onChange={handleChange}
                            name="checkedA"
                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                        />
                        <p>Endereço de entrega diferente</p>
                    </div>

                    {(request.addressDifferent) && <input
                        required={true}
                        maxLength={150}
                        value={request.address}
                        onChange={a => dispatch({ type: 'ADDRESS_REQUEST', address: a.target.value })}
                        placeholder="Endereço"
                    />}

                    {(request.addressDifferent) && <input
                        required={true}
                        maxLength={10}
                        value={request.number}
                        onChange={n => dispatch({ type: 'NUMBER_REQUEST', number: n.target.value })}
                        placeholder="Número"
                    />}

                    {(request.addressDifferent) && <div className="horizontalCheckout">
                        <input
                            readOnly={true}
                            value={request.neigh}
                            maxLength={150}
                            // onChange={n => dispatch({type: 'REG_NEIGH', neigh: n.target.value})}
                            placeholder="Bairro"
                        />
                        <button onClick={handleSelectNeigh} className="buttonNeighCheckout">
                            <FiArrowRight size={16} color="#E02041" />
                        </button>
                    </div>}

                    {(request.addressDifferent) && <input
                        value={request.complement}
                        maxLength={150}
                        onChange={c => dispatch({ type: 'COMPLEMENT_REQUEST', complement: c.target.value })}
                        placeholder="Complemento"
                    />}

                    <InputLabel className="lblFormaPgto" id="demo-simple-select-outlined-label">Forma de pagamento:</InputLabel>
                    <Select
                        required={true}
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={request.formPayment}
                        onChange={e => handleFormChange(e.target.value)}
                        label="Forma de pagamento"
                        className="selPgto">
                        {/* <MenuItem value="">
                            <em>None</em>
                        </MenuItem> */}
                        {formPayment.map(item => (
                            <MenuItem key={item.id} value={item.formPayment}>{item.formPayment}</MenuItem>
                        ))}
                        {/* <MenuItem value={'CARTAO CREDITO'}>Cartão Crédito</MenuItem>
                        <MenuItem value={'CARTAO DEBITO'}>Cartão Débito</MenuItem> */}
                    </Select>

                    <div className="totalCheckout">
                        <h4>Valor total dos produtos: {Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(request.totalProducts)}</h4>
                        <h4>Valor da taxa de entrega: {Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(request.feeDelivery)}</h4>
                        <h4>Desconto (retirar no balcão): {Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(discount)}</h4>
                        <h4>Valor total do pedido: {Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(request.totalRequest)}</h4>
                    </div>
                    <button className="button" type="submit">Finalizar</button>

                </form>
            </section>
        </div>
    );
}
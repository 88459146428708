const initialState = {
    call: ''
};

function CallLoadStorageReducer(state = initialState, action) {
    switch (action.type) {
        case 'ADD_STORAGE':
            return {
                ...state,
                call: action.call
            };
        case 'CLEAR_STORAGE':
            return {
                call: ''
            };
        default:
            return state;
    }
};

export default CallLoadStorageReducer;
import React, { useState } from "react";
import firebase from "../../services/firebaseConfig";
import { Link, useHistory } from "react-router-dom";
import { FiLogIn } from "react-icons/fi";

import "./styles.css";

import forgotImg from "../../assets/password.svg";
// import { useSelector } from 'react-redux';

export default function ForgotPassword() {
  const history = useHistory();
  // const provider = useSelector(state => state.provider);

  const [email, setEmail] = useState("");

  function handleSubmit(e) {
    e.preventDefault(e);
    if (email === '') {
        alert('Você precisa informar seu e-mail!');
    } else {
        firebase.auth().sendPasswordResetEmail(email).then(() => {
            history.push("/");       
        });   
    }
  }

  return (
    <div className="login-container">
      <section className="sectionForm">
        <img className="imgLogin" src={forgotImg} alt="Logo" />

        <form onSubmit={handleSubmit}>
          <div className="areaLogin">
            <p className="facaLogin">Esqueci minha senha</p>
          </div>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="E-mail"
          />
          <button className="button" type="button">
            Enviar link de recuperação
          </button>

          <Link className="backlink" to="/">
            <FiLogIn size={16} color="#E02041" />
            Voltar
          </Link>
        </form>
      </section>
    </div>
  );
}

import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import './styles.css';

// import logoCustomer from '../../assets/logo.png';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';

export default function CombinedCheckout() {

    const history = useHistory();

    const dispatch = useDispatch();

    const provider = useSelector(state => state.provider);
    // const flavorSelected = useSelector(state => state.flavorSelected);
    const flavors = useSelector(state => state.flavors);
    const groupSelected = useSelector(state => state.groupSelected);
    const request = useSelector(state => state.request);
    const items = useSelector(state => state.items);

    const [totalItem, setTotalItem] = useState(0);

    useEffect(() => {
        function loadStorage() {
            if (!provider.id) {
                dispatch({
                    type: 'ADD_STORAGE',
                    call: 'COMBINEDCHECKOUT'
                });
                history.push('/loadstorage');
            } else {
                let t = 0;
                let f = flavors;
                f.forEach((i) => {
                    t = t + i.total;
                });
                setTotalItem(t);
    
    
                // let amount = flavors.length;
                // dispatch({
                //     type: 'CLEAR_FLAVORS' 
                // });
                // // console.log(f);
                // f.forEach((i) => {
                //     let ads = i.additionals;
                //     let addUpdate = [];
                //     ads.forEach((a) => {
                //         addUpdate.push({
                //             id: a.id,
                //             description: a.description,
                //             price: a.price,
                //             total: (a.price / amount)
                //         });
                //     });
                //     dispatch({
                //         type: 'ADD_FLAVORS',
                //         id: i.id,
                //         idRequest: i.idRequest,
                //         code: i.code,
                //         description: i.description,
                //         unity: i.unity,
                //         amount: i.amount,
                //         price: i.price,
                //         totalFlavor: i.totalFlavor,
                //         codeEdge: i.codeEdge,
                //         edge: i.edge,
                //         priceEdge: i.priceEdge,
                //         totalEdge: i.totalEdge,
                //         total: i.total,
                //         comments: i.comments,
                //         additionals: addUpdate
                //     })
                // });                
            }
        }
        loadStorage();
    }, [
        dispatch,
        flavors,
        history,
        provider.id
    ]);

   
    function handleFinalize() {

        const idItem = Math.floor(Math.random() * (1000 - 1) + 1);

        dispatch({
            type: 'ADD_ITEM',
            id: idItem,
            idRequest: request.id,
            code: 'COMB',
            description: groupSelected.group,
            unity: 'UN',
            amount: 1,
            price: totalItem,
            total: totalItem,
            comments: '',
            combined: true,
            flavors: flavors
        });

        let storeItems = [];
        items.forEach((item) => {
            storeItems.push(JSON.stringify({
                'id': item.id,
                'idRequest': item.idRequest,
                'code': item.code,
                'description': item.description,
                'unity': item.unity,
                'amount': item.amount,
                'price': item.price,
                'total': item.total,
                'comments': item.comments,
                'combined': item.combined,
                'flavors': item.flavors
            }));
        });
        storeItems.push(JSON.stringify({
            'id': idItem,
            'idRequest': request.id,
            'code': groupSelected.code,
            'description': groupSelected.description,
            'unity': 'UN',
            'amount': 1,
            'price': totalItem,
            'total': totalItem,
            'comments': '',
            'combned': true,
            'flavors': flavors
        }));
        localStorage.setItem('items', JSON.stringify(storeItems));

        dispatch({
            type: 'CLEAR_FLAVORS',
        });

        localStorage.removeItem('flavors');

        history.push('/request');
    };

    function handleaddFlavor() {
        if (flavors.length < groupSelected.fractions) {
            history.push('/combinedOrder');
        } else {
            alert('Limite de sabores atingido!');
        }
    };

    function FlavorItem({ data }) {
        return (
            <div className="divItemCheckout">
                <div className="buttonItemCheckout">
                    <h5>{data.description} - {Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(data.totalFlavor)}</h5>
                    <h5>{data.edge} - {Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(data.totalEdge)}</h5>
                    <h5>Subtotal: {Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(data.total)}</h5>
                </div>
            </div>
        );
    };


    return (
        <div className="combCheck-container">
            <div className="combCheckHeader">
                <img className="combCheckLogo" src={provider.logo} alt="Logo" />
                <div className="orientation">
                    <h5 style={{ marginTop: '5px' }}>Resumo do produto</h5>
                    <h5 style={{ marginTop: '5px', marginBottom: '5px' }}>{groupSelected.group}</h5>
                    <h5 style={{ marginTop: '5px', marginBottom: '5px' }}>Sabores: {flavors.length}</h5>
                </div>
            </div>

            <div className="combCheckBoddy">

                {flavors.map(item => (
                    <FlavorItem
                        data={item}
                        key={item.id} />
                ))}

            </div>

            <h2>Valor final do produto: {Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(totalItem)}</h2>
            {/* <input className="obs" onChange={o => setComments(o.target.value)} placeholder="Observações..." /> */}

            <div className="combCheckFooter">
                <button onClick={() => handleFinalize()} className="combCheckButton" type="button">Aprovar este produto</button>
                <button onClick={() => handleaddFlavor()} className="combCheckButton" type="button">Adicionar outro sabor</button>
            </div>
        </div>
    );
}
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import './styles.css';

// import logoCustomer from '../../assets/logo.png';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';

export default function CombinedEdgeSingle() {

    const history = useHistory();

    const dispatch = useDispatch();

    const provider = useSelector(state => state.provider);
    const request = useSelector(state => state.request);
    const flavorSelected = useSelector(state => state.flavorSelected);
    const flavors = useSelector(state => state.flavors);
    const groupSelected = useSelector(state => state.groupSelected);
    const products = useSelector(state => state.products);
    const items = useSelector(state => state.items);


    const [totalItem, setTotalItem] = useState(0);

    const [comments, setComments] = useState('');

    const [additional, setAdditional] = useState([]);

    useEffect(() => {
        function loadStorage() {
            if (!provider.id) {
                dispatch({
                    type: 'ADD_STORAGE',
                    call: 'COMBINEDEDGE'
                });
                history.push('/loadstorage');
            } else {        // carrega os sabores das bordas
                let i = [];
                setAdditional([]);
                products.forEach((item) => {
                    if (item.edge === 'S') {
                        i.push({codeEdge: item.code, edge: item.description, priceEdge: item.price});
                    };                   
                });
                setAdditional(i);

                let t = 0;
                let f = flavors;
                f.forEach((i) => {
                    t = t + i.total;
                });
                setTotalItem(t);

            }
        }
        loadStorage();
    }, [
        dispatch,
        history,
        products,
        provider.id,
        flavors
    ]);

    function handleSelect(data) {
        let amountFlavors = flavors.length;
        let f = [];
        let tFlavor = 0;
        let totalRequest = 0;
        flavors.forEach(item => {
            f.push({
                id: item.id,
                idRequest: item.idRequest,
                code: item.code,
                description: item.description,
                unity: item.unity,
                amount: (1 / amountFlavors),
                price: item.price,
                totalFlavor: (item.price / amountFlavors),
                codeEdge: data.codeEdge,
                edge: data.edge,
                priceEdge: data.priceEdge,
                totalEdge: (data.priceEdge / amountFlavors),
                total: (item.price / amountFlavors) +
                       (data.priceEdge / amountFlavors),
                comments: item.comments
            })
            tFlavor = tFlavor + (item.price / amountFlavors);
            totalRequest = totalRequest + (item.price / amountFlavors) +
                                          (data.priceEdge / amountFlavors);
        })

        const idItem = Math.floor(Math.random() * (1000 - 1) + 1);

        dispatch({
            type: 'ADD_ITEM',
            id: idItem,
            idRequest: request.id,
            code: 'COMB',
            description: groupSelected.group,
            unity: 'UN',
            amount: 1,
            price: totalItem,
            total: totalRequest,
            comments: '',
            combined: true,
            flavors: f
        });

        let storeItems = [];
        items.forEach((item) => {
            storeItems.push(JSON.stringify({
                'id': item.id,
                'idRequest': item.idRequest,
                'code': item.code,
                'description': item.description,
                'unity': item.unity,
                'amount': item.amount,
                'price': item.price,
                'total': item.total,
                'comments': item.comments,
                'combined': item.combined,
                'flavors': f
            }));
        });
        storeItems.push(JSON.stringify({
            'id': idItem,
            'idRequest': request.id,
            'code': groupSelected.code,
            'description': groupSelected.description,
            'unity': 'UN',
            'amount': 1,
            'price': totalItem,
            'total': totalItem,
            'comments': comments,
            'combned': true,
            'flavors': f
        }));
        localStorage.setItem('items', JSON.stringify(storeItems));

        dispatch({
            type: 'CLEAR_FLAVORS',
        });

        localStorage.removeItem('flavors');

        history.push('/request');               
    }


    function handleBack() {
        history.push('/combinedOrder');

    };

    function handleWithoutEdge() {
        const idItem = Math.floor(Math.random() * (1000 - 1) + 1);

        dispatch({
            type: 'ADD_ITEM',
            id: idItem,
            idRequest: request.id,
            code: 'COMB',
            description: groupSelected.group,
            unity: 'UN',
            amount: 1,
            price: totalItem,
            total: totalItem,
            comments: '',
            combined: true,
            flavors: flavors
        });

        let storeItems = [];
        items.forEach((item) => {
            storeItems.push(JSON.stringify({
                'id': item.id,
                'idRequest': item.idRequest,
                'code': item.code,
                'description': item.description,
                'unity': item.unity,
                'amount': item.amount,
                'price': item.price,
                'total': item.total,
                'comments': item.comments,
                'combined': item.combined,
                'flavors': item.flavors
            }));
        });
        storeItems.push(JSON.stringify({
            'id': idItem,
            'idRequest': request.id,
            'code': groupSelected.code,
            'description': groupSelected.description,
            'unity': 'UN',
            'amount': 1,
            'price': totalItem,
            'total': totalItem,
            'comments': comments,
            'combned': true,
            'flavors': flavors
        }));
        localStorage.setItem('items', JSON.stringify(storeItems));

        dispatch({
            type: 'CLEAR_FLAVORS',
        });

        localStorage.removeItem('flavors');

        history.push('/request');        

    }

    function ProductItem({ data }) {
        return (
            <div className="divEdgeItem">
                <button onClick={() => handleSelect(data)} className="edgeItems">
                    <h4>{data.edge}</h4>
                    <p>{Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(data.priceEdge)}</p>
                </button>
            </div>
        );
    };

    return (
        <div className="edge-container">
            <div className="edgeHeader">
                <img className="logoEdge" src={provider.logo} alt="Logo" />
                <div className="orientation">
                    <h5 style={{ marginTop: '5px' }}>Escolha o sabor da borda para</h5>
                    <h5 style={{ marginTop: '5px', marginBottom: '5px' }}>{groupSelected.group}: {flavorSelected.description}</h5>
                </div>
            </div>

            {/* <h5>Observações:</h5> */}
            <input maxLength={150} className="obs" onChange={o => setComments(o.target.value)} placeholder="Observações..." />

            <div className="edgeBoddy">

                {additional.map(item => (
                    <ProductItem
                        data={item}
                        key={item.codeEdge} />
                ))}

            </div>

            <div className="edgeFooter">
                <button onClick={() => handleWithoutEdge()} className="edgeButton" type="button">Sem borda</button>
                <button onClick={() => handleBack()} className="edgeButton" type="button">Voltar</button>
            </div>
        </div>
    );
}
import React from 'react';
import { useHistory } from 'react-router-dom';
// import firebase from '../../services/firebaseConfig';
// import CircularProgress from '@material-ui/core/CircularProgress';

import './styles.css';
import { useSelector, useDispatch } from 'react-redux';

// import logoCustomer from '../../assets/logo.png';

export default function Neighborhood() {

    const history = useHistory();
    const dispatch = useDispatch();

    const provider = useSelector(state => state.provider);
    const neigh = useSelector(state => state.neigh);
    const callNeigh = useSelector(state => state.callNeigh);
    const request = useSelector(state => state.request);

    // const [working, setWorking] = useState(false);


    // useEffect(() => {
    //     function loadNeighborhood() {
    //         setWorking(true);
    //         dispatch({ type: 'CLEAR_NEIGH' });
    //         firebase.firestore().collection('neighborhood').orderBy("name")
    //             .where("idProvider", "==", provider.id)
    //             .where("active", "==", "S")
    //             .get()
    //             .then((result) => {
    //                 result.forEach((item) => {
    //                     dispatch({
    //                         type: 'ADD_NEIGH',
    //                         id: item.data().id,
    //                         code: item.data().code,
    //                         idProvider: item.data().idProvider,
    //                         name: item.data().name,
    //                         feeDelivery: item.data().feeDelivery,
    //                         city: item.data().city,
    //                         uf: item.data().uf,
    //                         ibge: item.data().ibge
    //                     })
    //                 });
    //                 setWorking(false);
    //             })
    //             .catch((erro) => {
    //                 console.log(erro);
    //             });
    //     }
    //     loadNeighborhood();
    // }, []);

    function handleSelectNeigh(data) {
        if (callNeigh.call === 'register') {
            dispatch({
                type: 'REG_NEIGH',
                neigh: data.name,
                idNeigh: data.id,
                feeDelivery: parseFloat(data.feeDelivery),
                city: data.city,
                uf: data.uf,
                ibge: data.ibge
            });
            history.push('/register');
        } else if (callNeigh.call === 'checkout') {
            dispatch({
                type: 'NEIGH_REQUEST',
                neigh: data.name,
                idNeigh: data.id
            });
            if (provider.feeDeliveryMode === 'B') {
                dispatch({
                    type: 'TOTAL_REQUEST',
                    feeDelivery: parseFloat(data.feeDelivery),
                    totalRequest: (request.totalProduct + parseFloat(data.feeDelivery))
                });
            };
            history.push('/checkout');
        }
    };

    function handleBack() {
        history.push('/register');
    }

    function NeighItem({ data }) {
        return (
            <div className="divNeigh">
                <button onClick={() => handleSelectNeigh(data)} className="neighItems">
                    <p>{data.name}</p>
                </button>
            </div>
        );
    }

    return (
        <div className="request-container">
            <div className="neighHeader">
                {(provider.logo !== '') && <img className="imgNeigh" src={provider.logo} alt="Logo" />}
                <h1>Lista de bairro atenditos</h1>
            </div>
            <div className="neighBoddy">

                {neigh.map(item => (
                    <NeighItem data={item} key={item.id} />
                ))}

            </div>
            <div className="containerFooter">
                <button onClick={() => handleBack()} className="neighButton" type="button">Voltar</button>
            </div>
        </div>
    );
}
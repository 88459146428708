const initialState = {
    id: '',
    email: '',
    password: '',
    name: '',
    cpf: '',
    zipcode: '',
    address: '',
    number: '',
    neigh: '',
    idNeigh: '',
    feeDelivery: 0,
    complement: '',
    city: '',
    uf: '',
    ibge: '',
    whats: '',
    comments: '',
    idProvider: '',
}

function UserReducer(state = initialState, action) {
    switch (action.type) {
        case 'ADD_USER':
            return {
                ...state,
                id: action.id,
                email: action.email,
                password: action.password,
                name: action.name,
                cpf: action.cpf,
                zipcode: action.zipcode,
                address: action.address,
                number: action.number,
                neigh: action.neigh,
                idNeigh: action.idNeigh,
                feeDelivery: action.feeDelivery,
                complement: action.complement,
                city: action.city,
                uf: action.uf,
                ibge: action.ibge,
                whats: action.whats,
                comments: action.comments,
                idProvicer: action.idProvider,
            };
        case 'CLEAR_USER':
            return {
                id: '',
                email: '',
                password: '',
                name: '',
                cpf: '',
                zipcode: '',
                address: '',
                number: '',
                neigh: '',
                idNeigh: '',
                feeDelivery: 0,
                complement: '',
                city: '',
                uf: '',
                ibge: '',
                whats: '',
                comments: '',
                idProvider: '',
            }
        default:
            return state;
    }
};

export default UserReducer;
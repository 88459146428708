import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import './styles.css';

// import logoCustomer from '../../assets/logo.png';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';

export default function CombinedEdge() {

    const history = useHistory();

    const dispatch = useDispatch();

    const provider = useSelector(state => state.provider);
    const request = useSelector(state => state.request);
    const flavorSelected = useSelector(state => state.flavorSelected);
    const flavors = useSelector(state => state.flavors);
    const groupSelected = useSelector(state => state.groupSelected);
    const products = useSelector(state => state.products);


    const [comments, setComments] = useState('');

    const [additional, setAdditional] = useState([]);

    useEffect(() => {
        function loadStorage() {
            if (!provider.id) {
                dispatch({
                    type: 'ADD_STORAGE',
                    call: 'COMBINEDEDGE'
                });
                history.push('/loadstorage');
            } else {        // carrega os sabores das bordas
                let i = [];
                setAdditional([]);
                products.forEach((item) => {
                    if (item.edge === 'S') {
                        i.push({codeEdge: item.code, edge: item.description, priceEdge: item.price});
                    };                   
                });
                setAdditional(i);
            }
        }
        loadStorage();
    }, [
        dispatch,
        history,
        products,
        provider.id
    ]);


    function handleSelect(data) {
        let flavorsStorage = [];
        let currentList = flavors;
        let amountFlavors = flavors.length + 1;
        dispatch({
            type: 'CLEAR_FLAVORS',
        });

        if (currentList) {
            currentList.forEach((item) => {
           
                dispatch({
                    type: 'ADD_FLAVORS',
                    id: item.id,
                    idRequest: item.idRequest,
                    code: item.code,
                    description: item.description,
                    unity: item.unity,
                    amount: (1 / amountFlavors),
                    price: item.price,
                    totalFlavor: (item.price / amountFlavors),
                    codeEdge: item.codeEdge,
                    edge: item.edge,
                    priceEdge: item.priceEdge,
                    totalEdge: (item.priceEdge / amountFlavors),
                    total: (item.price / amountFlavors) +
                           (item.priceEdge / amountFlavors),
                    comments: item.comments
                });
                flavorsStorage.push({
                    id: item.id,
                    idRequest: item.idRequest,
                    code: item.code,
                    description: item.description,
                    unity: item.unity,
                    amount: (1 / amountFlavors),
                    price: item.price,
                    totalFlavor: (item.price / amountFlavors),
                    codeEdge: item.codeEdge,
                    edge: item.edge,
                    priceEdge: item.priceEdge,
                    totalEdge: (item.priceEdge / amountFlavors),
                    total: (item.price / amountFlavors) +
                           (item.priceEdge / amountFlavors),
                    comments: item.comments
                })
            });   
        }
        // let totalAdd = 0;
        // additionalSel.forEach((i) => {
        //     totalAdd = totalAdd + (i.price / amountFlavors);
        // });
        dispatch({
            type: 'ADD_FLAVORS',
            id: flavorSelected.id,
            idRequest: request.id,
            code: flavorSelected.code,
            description: flavorSelected.description,
            unity: flavorSelected.unity,
            amount: (1 / amountFlavors),
            price: flavorSelected.price,
            totalFlavor: (flavorSelected.price / amountFlavors),
            codeEdge: data.codeEdge,
            edge: data.edge,
            priceEdge: data.priceEdge,
            totalEdge: (data.priceEdge / amountFlavors),
            total: (flavorSelected.price / amountFlavors) + (data.priceEdge / amountFlavors),
            comments: comments
        });
        flavorsStorage.push({
            id: flavorSelected.id,
            idRequest: request.id,
            code: flavorSelected.code,
            description: flavorSelected.description,
            unity: flavorSelected.unity,
            amount: (1 / amountFlavors),
            price: flavorSelected.price,
            totalFlavor: (flavorSelected.price / amountFlavors),
            codeEdge: data.codeEdge,
            edge: data.edge,
            priceEdge: data.priceEdge,
            totalEdge: (data.priceEdge / amountFlavors),
            total: (flavorSelected.price / amountFlavors) + (data.priceEdge / amountFlavors),
            comments: comments
        });
        localStorage.setItem('flavors', JSON.stringify(flavorsStorage));
        history.push('/combinedCheckout');        
    }


    function handleBack() {
        history.push('/combinedOrder');

    };

    function handleWithoutEdge() {
        let flavorsStorage = [];
        let currentList = flavors;
        let amountFlavors = flavors.length + 1;
        dispatch({
            type: 'CLEAR_FLAVORS',
        });

        if (currentList) {
            currentList.forEach((item) => {
                dispatch({
                    type: 'ADD_FLAVORS',
                    id: item.id,
                    idRequest: item.idRequest,
                    code: item.code,
                    description: item.description,
                    unity: item.unity,
                    amount: (1 / amountFlavors),
                    price: item.price,
                    totalFlavor: (item.price / amountFlavors),
                    codeEdge: item.codeEdge,
                    edge: item.edge,
                    priceEdge: item.priceEdge,
                    totalEdge: (item.priceEdge / amountFlavors),
                    total: (item.price / amountFlavors) +
                           (item.priceEdge / amountFlavors),
                    comments: item.comments
                });
                flavorsStorage.push({
                    id: item.id,
                    idRequest: item.idRequest,
                    code: item.code,
                    description: item.description,
                    unity: item.unity,
                    amount: (1 / amountFlavors),
                    price: item.price,
                    totalFlavor: (item.price / amountFlavors),
                    codeEdge: item.codeEdge,
                    edge: item.edge,
                    priceEdge: item.priceEdge,
                    totalEdge: (item.priceEdge / amountFlavors),
                    total: (item.price / amountFlavors) +
                           (item.priceEdge / amountFlavors),
                    comments: item.comments
                })
            });   
        }
        dispatch({
            type: 'ADD_FLAVORS',
            id: flavorSelected.id,
            idRequest: request.id,
            code: flavorSelected.code,
            description: flavorSelected.description,
            unity: flavorSelected.unity,
            amount: (1 / amountFlavors),
            price: flavorSelected.price,
            totalFlavor: (flavorSelected.price / amountFlavors),
            codeEdge: '',
            edge: '',
            priceEdge: 0,
            totalEdge: 0,
            total: (flavorSelected.price / amountFlavors) + 0,
            comments: comments
        });
        flavorsStorage.push({
            id: flavorSelected.id,
            idRequest: request.id,
            code: flavorSelected.code,
            description: flavorSelected.description,
            unity: flavorSelected.unity,
            amount: (1 / amountFlavors),
            price: flavorSelected.price,
            totalFlavor: (flavorSelected.price / amountFlavors),
            codeEdge: '',
            edge: '',
            priceEdge: 0,
            totalEdge: 0,
            total: (flavorSelected.price / amountFlavors) + 0,
            comments: comments
        });
        localStorage.setItem('flavors', JSON.stringify(flavorsStorage));
        history.push('/combinedCheckout');        
    }


    function ProductItem({ data }) {
        return (
            <div className="divEdgeItem">
                <button onClick={() => handleSelect(data)} className="edgeItems">
                    <h4>{data.edge}</h4>
                    <p>{Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(data.priceEdge)}</p>
                </button>
            </div>
        );
    };

    return (
        <div className="edge-container">
            <div className="edgeHeader">
                <img className="logoEdge" src={provider.logo} alt="Logo" />
                <div className="orientation">
                    <h5 style={{ marginTop: '5px' }}>Escolha o sabor da borda para</h5>
                    <h5 style={{ marginTop: '5px', marginBottom: '5px' }}>{groupSelected.group}: {flavorSelected.description}</h5>
                </div>
            </div>

            {/* <h5>Observações:</h5> */}
            <input maxLength={150} className="obs" onChange={o => setComments(o.target.value)} placeholder="Observações..." />

            <div className="edgeBoddy">

                {additional.map(item => (
                    <ProductItem
                        data={item}
                        key={item.codeEdge} />
                ))}

            </div>

            <div className="edgeFooter">
                <button onClick={() => handleWithoutEdge()} className="edgeButton" type="button">Sem borda</button>
                <button onClick={() => handleBack()} className="edgeButton" type="button">Voltar</button>
            </div>
        </div>
    );
}
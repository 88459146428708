const initialState = {
    email: '',
    password: '',
    name: '',
    cpf: '',
    zipcode: '',
    address: '',
    number: '',
    neigh: '',
    idNeigh: '',
    feeDelivery: 0,
    complement: '',
    city: '',
    uf: '',
    ibge: '',
    whats: '',
    comments: '',
}

function RegisterReducer(state = initialState, action) {
    switch (action.type) {
        case 'REG_ALLFIELDS':
            return {
                ...state,
                email: action.email,
                password: action.password,
                name: action.name,
                cpf: action.cpf,
                zipcode: action.zipcode,
                address: action.address,
                number: action.number,
                neigh: action.neigh,
                idNeigh: action.idNeigh,
                feeDelivery: action.feeDelivery,
                complement: action.complement,
                city: action.city,
                uf: action.uf,
                ibge: action.ibge,
                whats: action.whats,
                comments: action.comments,
            }
        case 'REG_EMAIL':
            return {
                ...state,
                email: action.email
            };
        case 'REG_PASSWORD':
            return {
                ...state,
                password: action.password
            };
        case 'REG_NAME':
            return {
                ...state,
                name: action.name
            };
        case 'REG_CPF':
            return {
                ...state,
                cpf: action.cpf
            };
        case 'REG_ZIPCODE':
            return {
                ...state,
                zipcode: action.zipcode
            };
        case 'REG_ADDRESS':
            return {
                ...state,
                address: action.address
            };
        case 'REG_NUMBER':
            return {
                ...state,
                number: action.number
            };
        case 'REG_NEIGH':
            return {
                ...state,
                neigh: action.neigh,
                idNeigh: action.idNeigh,
                feeDelivery: action.feeDelivery,
                city: action.city,
                uf: action.uf,
                ibge: action.ibge
            };
        case 'REG_COMPLEMENT':
            return {
                ...state,
                complement: action.complement
            };
        // case 'REG_CITY':
        //     return {
        //         ...state,
        //         city: action.city
        //     };
        // case 'REG_UF':
        //     return {
        //         ...state,
        //         uf: action.uf
        //     };
        case 'REG_WHATS':
            return {
                ...state,
                whats: action.whats
            };
        case 'REG_COMMENTS':
            return {
                ...state,
                comments: action.comments
            };
        default:
            return state;
    }
};

export default RegisterReducer;

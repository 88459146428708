const initialState = {
    id: '',
    idRequest: '',
    code: '',
    description: '',
    unity: '',
    amount: 1,
    price: 0,
    totalFlavor: 0,
    codeEdge: '',
    edge: '',
    priceEdge: 0,
    totalEdge: 0,
    total: 0,
    comments: ''
};

function FlavorSelectedReducer(state = initialState, action) {
    switch (action.type) {
        case 'ADD_FLAVORSEL':
            return {
                ...state,
                id: action.id,
                idRequest: action.idRequest,
                code: action.code,
                description: action.description,
                unity: action.unity,
                amount: action.amount,
                price: action.price,
            };
        case 'ADD_FLAVORSELEDGE':
            return {
                ...state,
                codeEdge: action.codeEdge,
                edge: action.edge,
                priceEdge: action.priceEdge,
                comments: action.comments,
            };
        // case 'ADD_FLAVORSELADD':
        //     return {
        //         ...state,
        //         additionals: action.additionals
        //     };
        case 'ADD_FLAVORTOTALIZE' :
            return {
                ...state,
                totalFlavor: action.totalFlavor,
                totalEdge: action.totalEdge,
                total: action.total
            };
        case 'CLEAR_FLAVORSEL':
            return {
                id: '',
                idRequest: '',
                code: '',
                description: '',
                unity: '',
                amount: 1,
                price: 0,
                totalFlavor: 0,
                codeEdge: '',
                edge: '',
                priceEdge: 0,
                totalEdge: 0,
                total: 0,
                comments: ''
            };
        default:
            return state;
    }
};

export default FlavorSelectedReducer;
import React, { useEffect, useState } from 'react';
import firebase from '../../services/firebaseConfig';
import { useHistory, useParams } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import './styles.css';
import { useDispatch } from 'react-redux';

export default function Provider() {

    let { idProvider } = useParams();

    const history = useHistory();
    const dispatch = useDispatch();

    const [working, setWorking] = useState(false);

    useEffect(() => {
        function loadProvide() {
            setWorking(true);
            localStorage.clear();
            firebase.auth().signInWithEmailAndPassword('freitagnx@gmail.com', '3oiqecdc')
                .then(() => {
                    firebase.firestore().collection("providers").orderBy("name").where("id", "==", idProvider)
                        .get().then((result) => {
                            result.forEach((item) => {
                                dispatch({
                                    type: 'ADD_PROVIDER',
                                    id: idProvider,
                                    logo: item.data().logo,
                                    email: item.data().email,
                                    password: item.data().password,
                                    cnpj: item.data().cnpj,
                                    fantasy: item.data().fantasy,
                                    feeDelivery: item.data().feeDelivery,
                                    feeDeliveryMode: item.data().feeDeliveryMode,
                                    ray: item.data().ray,
                                    open: item.data().open,
                                    singleEdge: item.data().singleEdge
                                });
                                localStorage.setItem('provider', JSON.stringify({
                                    'id': idProvider,
                                    'logo': item.data().logo,
                                    'email': item.data().email,
                                    'password': item.data().password,
                                    'cnpj': item.data().cnpj,
                                    'fantasy': item.data().fantasy,
                                    'feeDelivery': item.data().feeDelivery,
                                    'feeDeliveryMode': item.data().feeDeliveryMode,
                                    'ray': item.data().ray,
                                    'open': item.data().open,
                                    'singleEdge': item.data().singleEdge,
                                }));
                            });
                            history.push('/');
                        });
                })
                .catch((erro) => {
                    alert(erro);
                });
        };

        function loadProducts() {
            firebase.firestore().collection("products").orderBy("description")
                .where("idProvider", "==", idProvider)
                .where("active", "==", "S").get()
                .then((result) => {
                    let allGroups = [];
                    result.forEach((item) => {
                        dispatch({
                            type: 'ADD_PRODUCT',
                            id: item.data().id,
                            idProvider: idProvider,
                            code: item.data().code,
                            description: item.data().description,
                            unity: item.data().unity,
                            price: item.data().price,
                            group: item.data().group,
                            subgroup: item.data().subgroup,
                            active: item.data().active,
                            fractioned: item.data().fractioned,
                            fractions: item.data().fractions,
                            edge: item.data().edge,
                            additional: item.data().additional,
                            portionSize: item.data().portionSize,
                            web_img_64: item.data().web_img_64
                        });
                        // console.log(item.data());
                        let insert = true;
                        allGroups.forEach((g) => {
                            if (g.group === item.data().subgroup) { insert = false };
                        });
                        // console.log(item.subgroup);
                        if (insert === true) {
                            allGroups.push({
                                id: item.data().id,
                                group: item.data().subgroup,
                                fractioned: item.data().fractioned,
                                fractions: item.data().fractions
                            });
                        };
                    });
                    allGroups.forEach((item) => {
                        dispatch({
                            type: 'ADD_GROUP',
                            id: item.id,
                            group: item.group,
                            fractioned: item.fractioned,
                            fractions: item.fractions
                        });
                    });
                });
        };
        function loadFormPayment() {
            firebase.firestore().collection("formPayment").orderBy("formPayment")
                .where("idProvider", "==", idProvider).get()
                .then((result) => {
                    dispatch({
                        type: 'CLEAR_PAYMENT',
                    });
                    result.forEach((item) => {
                        dispatch({
                            type: 'ADD_PAYMENT',
                            id: item.data().id,
                            formPayment: item.data().formPayment

                        });
                    });
                });
        };

        function loadNeighborhood() {
            setWorking(true);
            dispatch({ type: 'CLEAR_NEIGH' });
            firebase.firestore().collection('neighborhood').orderBy("name")
                .where("idProvider", "==", idProvider)
                .where("active", "==", "S")
                .get()
                .then((result) => {
                    result.forEach((item) => {
                        dispatch({
                            type: 'ADD_NEIGH',
                            id: item.data().id,
                            code: item.data().code,
                            idProvider: item.data().idProvider,
                            name: item.data().name,
                            feeDelivery: item.data().feeDelivery,
                            city: item.data().city,
                            uf: item.data().uf,
                            ibge: item.data().ibge
                        })
                    });
                    setWorking(false);
                })
                .catch((erro) => {
                    console.log(erro);
                });
        };

        loadNeighborhood();
        loadProvide();
        loadProducts();
        loadFormPayment();
    }, [
        idProvider,
        dispatch,
        history
    ])


    return (
        <div className="login-container">
            <section className="sectionForm">
                <h1>Carregando informações</h1>
                {(working) && <div className="divActivity">
                    <CircularProgress />
                    {/* <CircularProgress color="secondary" /> */}
                </div>}
            </section>
        </div>
    );
}
import React, { useEffect } from 'react';
import firebase from '../../services/firebaseConfig';
import { useHistory } from 'react-router-dom';
// import { FiPlusSquare } from 'react-icons/fi';
import { useSelector, useDispatch } from 'react-redux';
import { format } from 'date-fns';

import './styles.css';

import conta from '../../assets/conta.png';

export default function MyRequests() {

    const dispatch = useDispatch();
    const requestList = useSelector(state => state.requestList);
    // const items = useSelector(state => state.items);
    const provider = useSelector(state => state.provider);
    const user = useSelector(state => state.user);

    const history = useHistory();

    useEffect(() => {
        function analiseStorage() {
            if (!provider.id) {
                dispatch({
                    type: 'ADD_STORAGE',
                    call: 'MYREQUESTS'
                });
                history.push('/loadstorage');
            } else {
                if (user.name === '' || user.address === '' || user.whats === '') {
                    history.push('/registerConfirm');
                } else {
                    dispatch({
                        type: 'CLEAR_REQUESTLIST'
                    });
                    firebase.firestore().collection("requests")
                        .orderBy("dateRequest", "desc")
                        .where("idProvider", "==", provider.id)
                        .where("idCustomer", "==", user.id)
                        .onSnapshot((result) => {
                            // .then((result) => {
                            dispatch({
                                type: 'CLEAR_REQUESTLIST'
                            });
                            result.forEach((item, index) => {
                                dispatch({
                                    type: 'ADD_REQUESTLIST',
                                    id: item.data().id,
                                    idCustomer: item.data().idCustomer,
                                    idProvider: item.data().idProvider,
                                    dateRequest: item.data().dateRequest,
                                    totalProducts: item.data().totalProducts,
                                    feeDelivery: item.data().feeDelivery,
                                    totalRequest: item.data().totalRequest,
                                    status: item.data().status,
                                    addressDifferent: item.data().addressDifferent,
                                    comeGet: item.data().comeGet,
                                    address: item.data().address,
                                    number: item.data().number,
                                    neigh: item.data().neigh,
                                    idNeigh: item.data().idNeigh,
                                    complement: item.data().complement,
                                    formPayment: item.data().formPayment,
                                    items: item.data().items,
                                    ticket: item.data().ticket
                                });
                            });
                        });
                }
            }
        };
        analiseStorage();
    }, [
        dispatch,
        history,
        provider.id,
        user.address,
        user.id,
        user.name,
        user.whats
    ]);

    function handleBack() {
        firebase.auth().signOut()
            .then(() => {
                history.push('/');
            });
    };

    function handleNewRequest() {
        let ref = firebase.firestore().collection("requests").doc();
        let newDate = (new Date()).getTime();
        dispatch({
            type: 'ADD_REQUEST',
            id: ref.id,
            idCustomer: user.id,
            idProvider: provider.id,
            dateRequest: newDate,
            totalProducts: 0,
            feeDelivery: 0,
            totalRequest: 0,
            status: 'O',
            addressDifferent: false,
            comeGet: false,
            address: user.address,
            number: user.number,
            neigh: user.neigh,
            idNeigh: user.idNeigh,
            complement: user.complement,
            formPayment: ''
        });
        localStorage.setItem('request', JSON.stringify({
            'id': ref.id,
            'idCustomer': user.id,
            'idProvider': provider.id,
            'dateRequest': newDate,
            'totalProducts': 0,
            'feeDelivery': 0,
            'totalRequest': 0,
            'status': 'O',
            'addressDifferent': false,
            'comeGet': false,
            'address': user.address,
            'number': user.number,
            'neigh': user.neigh,
            'idNeigh': user.idNeigh,
            'complement': user.complement,
            'formPayment': ''
        }))

        history.push('/request');
    };

    function DataItem({ data }) {
        return (
            <div className="itemsMyReq">
                <div className="horiz">
                    <div className="vert">
                        <p className="product"> Id.: {data.id} </p>
                        <p className="product"> Data: {format(data.dateRequest, 'dd/MM/yyyy')} </p>
                        <p className="product"> Valor Total: {Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' })
                            .format(data.totalRequest)} </p>
                        {(data.status === 'O') && <p style={{ color: "black" }} className="product"> Pedido realizado </p>}
                        {(data.status === 'P') && <p style={{ color: "orange" }} className="product"> Pedido em produção </p>}
                        {(data.status === 'S') && <p style={{ color: "gold" }} className="product"> Pedido a caminho </p>}
                        {(data.status === 'C') && <p style={{ color: "red" }} className="product"> Cancelado </p>}
                        {(data.status === 'D') && <p style={{ color: "green" }} className="product"> Pedido entregue </p>}
                    </div>
                    <button onClick={() => OpenUrl(data.ticket)} className="ticket">
                        <img className="imgPedido" src={conta} alt="Logo" />
                    </button>
                </div>
            </div>
        );
    }

    function OpenUrl(ticket) {
        if (ticket) {
            let t = ticket;
            var win = window.open();
            win.document.write('<iframe src="' + t + '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>');
        }
    };

    return (
        <div className="request-container">
            <div className="reader">
                {(provider.logo !== '') && <img className="logoMyRequests" src={provider.logo} alt="Logo" />}
                <h1>Meus pedidos</h1>
                <h4>{user.name}</h4>
            </div>
            <div className="boddyMyRequests">

                {requestList.map(item => (
                    <DataItem data={item} key={item.id} />
                ))}

            </div>

            <div className="containerFooter">
                <button onClick={() => handleNewRequest()} className="button" type="button">Novo pedido</button>
                <button onClick={() => handleBack()} className="button" type="button">Sair</button>
            </div>
        </div>
    );
}
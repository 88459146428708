import { combineReducers } from 'redux';
import RequestReducer from './reducers/RequestReducer';
import RequestListReducer from './reducers/RequestListReducer';
import ItemsReducer from './reducers/ItemsReducer';
import ItemSelected from './reducers/ItemSelectedReducer';
import ProductsReducer from './reducers/ProductsReducer';
import GroupsReducer from './reducers/GroupsReducer';
import GroupSelectedReducer from './reducers/GroupSelectedReducer';
import ProviderReducer from './reducers/ProviderReducer';
import UserReducer from './reducers/UserReducer';
import RegisterReducer from './reducers/RegisterReducer';
import NeighReducer from './reducers/NeighReducer';
import CallNeighReducer from './reducers/CallNeighReducer';
import PaymentsReducer from './reducers/PaymentsReducer';
import CallLoadStorage from './reducers/CallLoadStorageReduces';
import ProductsFilterReducer from './reducers/ProductsFilterReducer';
import FlavorsReducer from './reducers/FlavorsReducer';
import FlavorSelectedReducer from './reducers/FlavorSelectedReducer';

const combReducers = combineReducers( {

    request: RequestReducer,
    requestList: RequestListReducer,
    items: ItemsReducer,
    itemSelected: ItemSelected,
    products: ProductsReducer,
    productsFilter: ProductsFilterReducer,
    groups: GroupsReducer,
    groupSelected: GroupSelectedReducer,
    provider: ProviderReducer,
    user: UserReducer,
    register: RegisterReducer,
    neigh: NeighReducer,
    callNeigh: CallNeighReducer,
    formPayment: PaymentsReducer,
    callStorage: CallLoadStorage,
    flavors: FlavorsReducer,
    flavorSelected: FlavorSelectedReducer,    
});

export default combReducers;
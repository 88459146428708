import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { FiArrowLeft, FiArrowRight } from 'react-icons/fi';
import CircularProgress from '@material-ui/core/CircularProgress';
import firebase from '../../services/firebaseConfig';

import './styles.css';

import { useDispatch, useSelector } from 'react-redux';

export default function Register() {

    const history = useHistory();

    const dispatch = useDispatch();

    const provider = useSelector(state => state.provider);
    const register = useSelector(state => state.register);

    const [working, setWorking] = useState(false);


    function handleRegister(e) {
        e.preventDefault(e);
        if (register.neigh === '') {
            alert("Indique o bairro antes de finalizar o cadastro!");
        } else {
            setWorking(true);
            firebase.auth().createUserWithEmailAndPassword(register.email, register.password)
            .then(() => {
                let uid = firebase.auth().currentUser.uid;
                // console.log(register);
                firebase.firestore().collection('customers').doc(uid).set({
                    id: uid,
                    email: register.email,
                    password: register.password,
                    name: register.name,
                    cpf: register.cpf,
                    zipcode: register.zipcode,
                    address: register.address,
                    number: register.number,
                    neigh: register.neigh,
                    idNeigh: register.idNeigh,
                    feeDelivery: register.feeDelivery,
                    complement: register.complement,
                    city: register.city,
                    uf: register.uf,
                    ibge: register.ibge,
                    whats: register.whats,
                    comments: register.comments,
                    dateRegister: (new Date()).getTime(),
                    read: false,
                    idProvider: provider.id
                })
                    .then(() => {
                        setWorking(false);
                        history.push('/');
                    })
                    .catch((erro) => {
                        setWorking(false);
                        alert('Erro ao realizar o cadastro! Por gentileza, tente novamente!');
                    });
            })
            .catch((erro) => {
                if (erro.code === 'auth/email-already-in-use') {
                    firebase.auth().signInWithEmailAndPassword(register.email, register.password)
                        .then(() => {
                            setWorking(false);
                            handleLogin();
                        });
                } else {
                    alert(erro.code);
                }
            });
        }
    };

    function handleLogin() {
        // e.preventDefault(e);       
        localStorage.removeItem('gselected');
        localStorage.removeItem('request');
        localStorage.removeItem('flavorSelected');
        localStorage.removeItem('iSelected');
        if (provider.open === 'S') {
            // console.log(groups);
            firebase.auth().signInWithEmailAndPassword(register.email, register.password)
                .then(async () => {
                    let uid = firebase.auth().currentUser.uid;
                    // localStorage.setItem('email', email);
                    // localStorage.setItem('password', password);
                    firebase.firestore().collection("customers").where("id", "==", uid).get()
                        .then((result) => {
                            result.forEach((item) => {
                                dispatch({
                                    type: 'ADD_USER',
                                    id: uid,
                                    email: register.email,
                                    password: register.password,
                                    name: item.data().name,
                                    cpf: item.data().cpf,
                                    zipcode: item.data().zipcode,
                                    address: item.data().address,
                                    number: item.data().number,
                                    neigh: item.data().neigh,
                                    idNeigh: item.data().idNeigh,
                                    feeDelivery: item.data().feeDelivery,
                                    complement: item.data().complement,
                                    city: item.data().city,
                                    uf: item.data().uf,
                                    ibge: item.data().ibge,
                                    whats: item.data().whats,
                                    comments: item.data().comments,
                                    idProvider: item.data().idProvider
                                });
                                localStorage.setItem('user', JSON.stringify({
                                    'id': uid,
                                    'email': register.email,
                                    'password': register.password,
                                    'name': item.data().name,
                                    'cpf': item.data().cpf,
                                    'zipcode': item.data().zipcode,
                                    'address': item.data().address,
                                    'number': item.data().number,
                                    'neigh': item.data().neigh,
                                    'idNeigh': item.data().idNeigh,
                                    'feeDelivery': item.data().feeDelivery,
                                    'complement': item.data().complement,
                                    'city': item.data().city,
                                    'uf': item.data().uf,
                                    'ibge': item.data().ibge,
                                    'whats': item.data().whats,
                                    'comments': item.data().comments,
                                    'idProvider': item.data().idProvider
                                }));
                            })
                            history.push('/myrequests');
                        });
                    // localStorage.setItem('id', uid)
                })
                .catch((erro) => {
                    if (erro.code === 'auth/wrong-password') {
                        alert('Senha errada!')
                    } else if (erro.code === 'auth/user-not-found') {
                        alert('Usuário não encontrado!')
                    } else {
                        alert(erro.code);
                    }
                });
        } else {
            alert('Estabelecimento fechado!');
        }
    };


    function handleSelectNeigh() {
        dispatch({
            type: 'ADD_CALL',
            call: 'register'
        });
        history.push('/neighborhood');
    };

    return (
        <div className="register-container">
            <section className="sectionRegister">
                <img className="imgRegister" src={provider.logo} alt="Logo" />

                <form onSubmit={handleRegister}>
                    <h1>Crie uma conta</h1>
                    <input
                        maxLength={150}
                        required={true}
                        type="email"
                        value={register.email}
                        onChange={e => dispatch({ type: 'REG_EMAIL', email: e.target.value })}
                        placeholder="E-mail"
                    />
                    <input
                        maxLength={150}
                        required={true}
                        type="password"
                        value={register.password}
                        onChange={p => dispatch({ type: 'REG_PASSWORD', password: p.target.value })}
                        placeholder="Senha"
                    />
                    <input
                        maxLength={150}
                        required={true}
                        value={register.name}
                        onChange={n => dispatch({ type: 'REG_NAME', name: n.target.value })}
                        placeholder="Nome completo"
                    />
                    <input
                        maxLength={20}
                        required={true}
                        value={register.cpf}
                        onChange={n => dispatch({ type: 'REG_CPF', cpf: n.target.value })}
                        placeholder="CPF/CNPJ"
                    />
                    <input
                        maxLength={10}
                        required={true}
                        value={register.zipcode}
                        onChange={z => dispatch({ type: 'REG_ZIPCODE', zipcode: z.target.value })}
                        placeholder="CEP"
                    />
                    <input
                        maxLength={150}
                        required={true}
                        value={register.address}
                        onChange={a => dispatch({ type: 'REG_ADDRESS', address: a.target.value })}
                        placeholder="Endereço"
                    />
                    <input
                        maxLength={10}
                        required={true}
                        value={register.number}
                        onChange={n => dispatch({ type: 'REG_NUMBER', number: n.target.value })}
                        placeholder="Número"
                    />
                    <div className="horizontalRegister">
                        <input
                            required={true}
                            readOnly={true}
                            value={register.neigh}
                            // onChange={n => dispatch({type: 'REG_NEIGH', neigh: n.target.value})}
                            placeholder="Clique para selecionar o bairro -> "
                        />
                        <button onClick={handleSelectNeigh} className="buttonNeigh">
                            <FiArrowRight size={16} color="#E02041" />
                        </button>

                    </div>
                    <input
                        value={register.complement}
                        onChange={c => dispatch({ type: 'REG_COMPLEMENT', complement: c.target.value })}
                        placeholder="Edifício, esquina, ponto de referência..."
                    />
                    <input
                        required={true}
                        value={register.city}
                        // onChange={c => dispatch({ type: 'REG_CITY', city: c.target.value })}
                        placeholder="Cidade"
                        readOnly={true}
                    />
                    <input
                        required={true}
                        value={register.uf}
                        // onChange={u => dispatch({ type: 'REG_UF', uf: u.target.value })}
                        placeholder="Estado"
                        readOnly={true}
                    />
                    <input
                        maxLength={15}
                        required={true}
                        value={register.whats}
                        onChange={w => dispatch({ type: 'REG_WHATS', whats: w.target.value })}
                        placeholder="Whatsapp/Celular"
                    />
                    <input
                        maxLength={150}
                        value={register.comments}
                        onChange={c => dispatch({ type: 'REG_COMMENTS', comments: c.target.value })}
                        placeholder="Obs., alergias, etc..."
                    />

                    {(working) && <div className="divActivity">
                        <CircularProgress />
                        {/* <CircularProgress color="secondary" /> */}
                    </div>}

                    <button className="button" type="submit">Cadastrar</button>

                    {(!working) && <Link className="backlink" to="/">
                        <FiArrowLeft size={16} color="#E02041" />
                        Já possuo uma conta
                    </Link>}
                </form>
            </section>
        </div>
    );
}
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { FiArrowRight } from 'react-icons/fi';
import CircularProgress from '@material-ui/core/CircularProgress';
import firebase from '../../services/firebaseConfig';

import './styles.css';

import { useDispatch, useSelector } from 'react-redux';

export default function Register() {

    const history = useHistory();

    const dispatch = useDispatch();

    const provider = useSelector(state => state.provider);
    const register = useSelector(state => state.register);
    const user = useSelector(state => state.user);

    const [working, setWorking] = useState(false);

    useEffect(() => {
        function loadUser() {
            console.log(user);
            dispatch({
                type: 'REG_ALLFIELDS',
                email: user.email,
                password: user.password,
                name: user.name,
                cpf: user.cpf,
                zipcode: user.zipcode,
                address: user.address,
                number: user.number,
                neigh: user.neigh,
                idNeigh: user.idNeigh,
                feeDelivery: user.feeDelivery,
                complement: user.complement,
                city: user.city,
                uf: user.uf,
                ibge: user.ibge,
                whats: user.whats,
                comments: user.comments,
            })
        };
        loadUser();
    }, [
        dispatch,
        user
    ]);

    function handleRegister(e) {
        e.preventDefault(e);
        if (register.neigh === '') {
            alert("Indique o bairro antes de finalizar o cadastro!");
        } else {

            setWorking(true);
            let uid = firebase.auth().currentUser.uid;
            let newCustomer = {
                id: uid,
                email: register.email,
                password: register.password,
                name: register.name,
                cpf: register.cpf,
                zipcode: register.zipcode,
                address: register.address,
                number: register.number,
                neigh: register.neigh,
                idNeigh: register.idNeigh,
                feeDelivery: register.feeDelivery,
                complement: register.complement,
                city: register.city,
                uf: register.uf,
                ibge: register.ibge,
                whats: register.whats,
                comments: register.comments,
                dateRegister: (new Date()).getTime(),
                read: false,
                idProvider: provider.id
            };

            dispatch({
                type: 'ADD_USER',
                id: uid,
                email: newCustomer.email,
                password: newCustomer.password,
                name: newCustomer.name,
                cpf: newCustomer.cpf,
                zipcode: newCustomer.zipcode,
                address: newCustomer.address,
                number: newCustomer.number,
                neigh: newCustomer.neigh,
                idNeigh: newCustomer.idNeigh,
                feeDelivery: newCustomer.feeDelivery,
                complement: newCustomer.complement,
                city: newCustomer.city,
                uf: newCustomer.uf,
                ibge: newCustomer.ibge,
                whats: newCustomer.whats,
                comments: newCustomer.comments,
                idProvider: newCustomer.idProvider
            });

            // console.log(newCustomer)
            // setWorking(true);
            // console.log(register);
            firebase.firestore().collection('customers').doc(newCustomer.id).set({
                id: newCustomer.id,
                email: newCustomer.email,
                password: newCustomer.password,
                name: newCustomer.name,
                cpf: newCustomer.cpf,
                zipcode: newCustomer.zipcode,
                address: newCustomer.address,
                number: newCustomer.number,
                neigh: newCustomer.neigh,
                idNeigh: newCustomer.idNeigh,
                feeDelivery: newCustomer.feeDelivery,
                complement: newCustomer.complement,
                city: newCustomer.city,
                uf: newCustomer.uf,
                ibge: newCustomer.ibge,
                whats: newCustomer.whats,
                comments: newCustomer.comments,
                dateRegister: (new Date()).getTime(),
                read: false,
                idProvider: provider.id
            })
                .then(() => {
                    setWorking(false);
                    history.push('/myrequests');
                })
                .catch((erro) => {
                    setWorking(false);
                    alert('Erro ao realizar o cadastro! Por gentileza, tente novamente!');
                });
        }
    };


    function handleSelectNeigh() {
        dispatch({
            type: 'ADD_CALL',
            call: 'register'
        });
        history.push('/neighborhood');
    };

    function handleExit() {
        firebase.auth().signOut();
        history.push('/');
    };

    return (
        <div className="registerConfirm-container">
            <section className="sectionRegisterConfirm">
                <img className="imgRegisterConfirm" src={provider.logo} alt="Logo" />

                <form onSubmit={handleRegister}>
                    <h1 style={{ width: "100%", textAlign: 'center' }}>Complete seu cadastro</h1>
                    <input
                        maxLength={150}
                        required={true}
                        type="email"
                        value={register.email}
                        onChange={e => dispatch({ type: 'REG_EMAIL', email: e.target.value })}
                        placeholder="E-mail"
                    />
                    <input
                        maxLength={150}
                        required={true}
                        type="password"
                        value={register.password}
                        onChange={p => dispatch({ type: 'REG_PASSWORD', password: p.target.value })}
                        placeholder="Senha"
                    />
                    <input
                        maxLength={150}
                        required={true}
                        value={register.name}
                        onChange={n => dispatch({ type: 'REG_NAME', name: n.target.value })}
                        placeholder="Nome completo"
                    />
                    <input
                        maxLength={20}
                        required={true}
                        value={register.cpf}
                        onChange={n => dispatch({ type: 'REG_CPF', cpf: n.target.value })}
                        placeholder="CPF/CNPJ"
                    />
                    <input
                        maxLength={10}
                        required={true}
                        value={register.zipcode}
                        onChange={z => dispatch({ type: 'REG_ZIPCODE', zipcode: z.target.value })}
                        placeholder="CEP"
                    />
                    <input
                        maxLength={150}
                        required={true}
                        value={register.address}
                        onChange={a => dispatch({ type: 'REG_ADDRESS', address: a.target.value })}
                        placeholder="Endereço"
                    />
                    <input
                        maxLength={10}
                        required={true}
                        value={register.number}
                        onChange={n => dispatch({ type: 'REG_NUMBER', number: n.target.value })}
                        placeholder="Número"
                    />
                    <div className="horizontalRegister">
                        <input
                            required={true}
                            readOnly={true}
                            value={register.neigh}
                            // onChange={n => dispatch({type: 'REG_NEIGH', neigh: n.target.value})}
                            placeholder="Clique para selecionar o bairro -> "
                        />
                        <button onClick={handleSelectNeigh} className="buttonNeighConfirm">
                            <FiArrowRight size={16} color="#E02041" />
                        </button>

                    </div>
                    <input
                        value={register.complement}
                        onChange={c => dispatch({ type: 'REG_COMPLEMENT', complement: c.target.value })}
                        placeholder="Complemento"
                    />
                    <input
                        required={true}
                        value={register.city}
                        // onChange={c => dispatch({ type: 'REG_CITY', city: c.target.value })}
                        placeholder="Cidade"
                        readOnly={true}
                    />
                    <input
                        required={true}
                        value={register.uf}
                        // onChange={u => dispatch({ type: 'REG_UF', uf: u.target.value })}
                        placeholder="Estado"
                        readOnly={true}
                    />
                    <input
                        maxLength={15}
                        required={true}
                        value={register.whats}
                        onChange={w => dispatch({ type: 'REG_WHATS', whats: w.target.value })}
                        placeholder="Whatsapp/Celular"
                    />
                    <input
                        maxLength={150}
                        value={register.comments}
                        onChange={c => dispatch({ type: 'REG_COMMENTS', comments: c.target.value })}
                        placeholder="Obs., alergias, etc..."
                    />

                    {(working) && <div className="divActivityConfirm">
                        <CircularProgress />
                        {/* <CircularProgress color="secondary" /> */}
                    </div>}

                    <button className="button" type="submit">Gravar</button>
                    <button onClick={handleExit} className="button" type="button">Sair</button>

                </form>
            </section>
        </div>
    );
}
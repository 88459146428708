import React, { useEffect, useCallback } from 'react';
// import firebase from '../../services/firebaseConfig';
import { useHistory } from 'react-router-dom';
// import { FiPlusSquare } from 'react-icons/fi';
import { useSelector, useDispatch } from 'react-redux';

import './styles.css';

// import logoCustomer from '../../assets/logo.png';

export default function Request() {

    const dispatch = useDispatch();
    const request = useSelector(state => state.request);
    const items = useSelector(state => state.items);
    const provider = useSelector(state => state.provider);
    // const user = useSelector(state => state.user);

    const history = useHistory();

    const updateTotalProducts = useCallback(
        () => {
            let total = 0;
            items.forEach((item) => {
                total = total + item.total;
            });
            dispatch({
                type: 'TPRODUCTS_REQUEST',
                totalProducts: total
            });
            localStorage.setItem('request', JSON.stringify({
                'id': request.id,
                'idCustomer': request.idCustomer,
                'idProvider': request.idProvider,
                'dateRequest': request.dateRequest,
                'totalProducts': total,
                'feeDelivery': request.feeDelivery,
                'totalRequest': request.totalRequest,
                'status': request.status, // S - enviado // P - produced // C - canceled // D - delivered 
                'addressDifferent': request.addressDifferent,
                'comeGet': request.comeGet,
                'address': request.address,
                'number': request.number,
                'neigh': request.neigh,
                'idNeigh': request.idNeigh,
                'complement': request.complement,
                'formPayment': request.formPayment
            }));   
        }
    , [
        dispatch,
        items,
        request.address,
        request.addressDifferent,
        request.comeGet,
        request.complement,
        request.dateRequest,
        request.feeDelivery,
        request.formPayment,
        request.id,
        request.idCustomer,
        request.idNeigh,
        request.idProvider,
        request.neigh,
        request.number,
        request.status,
        request.totalRequest
    ]);

    useEffect(() => {
        function loadStorage() {
            if (!provider.id) {
                dispatch({
                    type: 'ADD_STORAGE',
                    call: 'REQUEST'
                });
                history.push('/loadstorage');
            } else {
                updateTotalProducts();
            };
        };
        loadStorage();
    }, [
        dispatch,
        history,
        provider.id,
        updateTotalProducts
    ]);

    function handleSendRequest() {
        if (items.length > 0) {
            history.push('/checkout');
        } else {
            alert('Lista de produtos vazia!');
        }
    };

    function handleExit() {
        clearRequest();
        history.push('/myrequests');
    };

    function clearRequest() {
        dispatch({
            type: 'CLEAR_REQUEST',
        });
        localStorage.removeItem('request');
        dispatch({
            type: 'CLEAR_ITEM',
        });
        localStorage.removeItem('items');
    };

    function handleDelete(data) {

        let total = 0;
        items.forEach((item) => {
            if (item.id !== data.id) {
                total = total + item.total;
            }
        });
        // console.log(total);
        dispatch({
            type: 'TPRODUCTS_REQUEST',
            totalProducts: total
        });

        localStorage.setItem('request', JSON.stringify({
            'id': request.id,
            'idCustomer': request.idCustomer,
            'idProvider': request.idProvider,
            'dateRequest': request.dateRequest,
            'totalProducts': total,
            'feeDelivery': request.feeDelivery,
            'totalRequest': request.totalRequest,
            'status': request.status, // S - enviado // P - produced // C - canceled // D - delivered 
            'addressDifferent': request.addressDifferent,
            'comeGet': request.comeGet,
            'address': request.address,
            'number': request.number,
            'neigh': request.neigh,
            'idNeigh': request.idNeigh,
            'complement': request.complement,
            'formPayment': request.formPayment
        }));


        dispatch({
            type: 'DEL_ITEM',
            id: data.id,
            idRequest: data.idRequest,
            code: data.code,
            description: data.description,
            unity: data.unity,
            amount: data.amount,
            price: data.price,
            total: data.total,
            comments: data.comments
        });
        let storeItems = [];
        items.forEach((item) => {
            if (item.id !== data.id) {
                storeItems.push(JSON.stringify({
                    'id': item.id,
                    'idRequest': item.idRequest,
                    'code': item.code,
                    'description': item.description,
                    'unity': item.unity,
                    'amount': item.amount,
                    'price': item.price,
                    'total': item.total,
                    'comments': item.comments
                }));
            }
        });
        localStorage.setItem('items', JSON.stringify(storeItems));
    }

    function handleAdd() {
        history.push('/groups');
    };

    function DataFlavor({ data }) {
        return (
            <div>
                <h5> {data.amount.toFixed(2)}  {data.description}  {data.edge}</h5>
                {/* {data.additionals.map(item => (
                    <DataAdittionals data={item} key={item.id} />
                ))} */}
            </div>
        );
    };

    // function DataAdittionals({ data }) {
    //     return (
    //         <h6 style={{ marginLeft: '10px' }}> +{data.amount}  {data.description}  {data.edge}</h6>
    //     );
    // };

    function DataItem({ data }) {
        if (data.combined) {
            return (
                <div className="itemsReCombined">
                    <div className="horizontal">
                        <div className="vertical">
                            <h4 className="product"> {data.amount} {data.description} </h4>
                            <p className="product"> {Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(data.total)} </p>
                            <p className="product"> {data.comments} </p>
                            {/* lista os sabores selecionados */}
                            <h5>Sabores</h5>
                            {data.flavors.map(item => (
                                <DataFlavor data={item} key={item.id} />
                            ))}
                        </div>
                        <div className="containerItem">
                            <button onClick={() => handleDelete(data)} className="buttonDel">Excluir</button>
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <div className="items">
                    <div className="horizontal">
                        <div className="vertical">
                            <h4 className="product"> {data.amount} {data.description} </h4>
                            <p className="product"> {Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(data.total)} </p>
                            <p className="product"> {data.comments} </p>
                        </div>
                        <div className="containerItem">
                            <button onClick={() => handleDelete(data)} className="buttonDel">Excluir</button>
                        </div>
                    </div>
                </div>
            );
        }
    }

    return (
        <div className="request-container">
            <div className="reader">
                {(provider.logo !== '') && <img className="logoRequest" src={provider.logo} alt="Logo" />}
                <h3>PEDIDO {request.id} </h3>

            </div>
            <div className="boddy">

                {items.map(item => (
                    <DataItem data={item} key={item.id} />
                ))}

            </div>
            <div className="horizontalAdd">
                <div className="total">
                    <p> Total: {Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(request.totalProducts)}</p>
                    {/* <p> Taxa de Entrega: {Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(request.feeDelivery)} </p>
                    <p> Total do Pedido: {Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(request.totalRequest)} </p> */}
                </div>
                <button onClick={() => handleAdd()} className="add">
                    Adicionar produto
                    {/* <FiPlusSquare size={20} color="white" /> */}
                </button>
            </div>

            <div className="containerFooter">
                <div className="horizontal">
                    <button onClick={() => handleExit()} className="buttonRequest" type="button">Cancelar</button>
                    <button style={{ marginLeft: '5px' }} onClick={() => handleSendRequest()} className="buttonRequest" type="button">Enviar pedido</button>
                </div>
            </div>
        </div>
    );
}
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';


const config = {
  apiKey: "AIzaSyAD3-51Sk8rw9NbjJ07_xe9f3MO3Ij8X3Q",
  authDomain: "mettre-a6183.firebaseapp.com",
  databaseURL: "https://mettre-a6183.firebaseio.com",
  projectId: "mettre-a6183",
  storageBucket: "mettre-a6183.appspot.com",
  messagingSenderId: "444725282441",
  appId: "1:444725282441:web:b694c7611123b7e6152858"
};
// Initialize Firebase
firebase.initializeApp(config);

export default firebase;
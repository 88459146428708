import React from 'react';
import { useHistory } from 'react-router-dom';

import './styles.css';

// import logoCustomer from '../../assets/logo.png';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';

// import { makeStyles } from '@material-ui/core/styles';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

export default function Products() {
    // const classes = useStyles();

    const history = useHistory();

    const dispatch = useDispatch();

    const provider = useSelector(state => state.provider);
    const groupSelected = useSelector(state => state.groupSelected);
    const productsFilter = useSelector(state => state.productsFilter);
    const products = useSelector(state => state.products);
    // const [filterProducts, setFilterProducts] = useState([]);

    useEffect(() => {
        function loadStorage() {
            if (!provider.id) {
                dispatch({
                    type: 'ADD_STORAGE',
                    call: 'PRODUCTS'
                });
                history.push('/loadstorage');
            };
        }
        loadStorage();
    }, [dispatch, history, provider.id]);

    useEffect(() => {
        function filterProducts() {
            dispatch({
                type: 'CLEAR_PRODUCTFILTER',
            });
            products.forEach((item) => {
                if (item.subgroup === groupSelected.group) {
                    dispatch({
                        type: 'ADD_PRODUCTFILTER',
                        id: item.id,
                        idProvider: item.idProvider,
                        code: item.code,
                        description: item.description,
                        unity: item.unity,
                        price: item.price,
                        group: item.group,
                        subgroup: item.subgroup,
                        active: item.active,
                        fractioned: item.fractioned,
                        fractions: item.fractions,
                        edge: item.edge,
                        additional: item.additional,
                        portionSize: item.portionSize,
                        web_img_64: item.web_img_64
                    });
                };
            });
        }
        filterProducts();
    }, [dispatch, groupSelected.group, products]);


    function handleProductDetail(data) {
        dispatch({
            type: 'ADD_ITEMSELECTED',
            id: data.id,
            idRequest: data.idRequest,
            code: data.code,
            description: data.description,
            unity: data.unity,
            price: data.price,
        });
        localStorage.setItem('iselected', JSON.stringify({
            'id': data.id,
            'idRequest': data.idRequest,
            'code': data.code,
            'description': data.description,
            'unity': data.unity,
            'price': data.price,
        }));
        history.push('/productDetail');
    }

    function handleBack() {
        history.push('/groups');
    }

    function ProductItem({ data }) {
        return (
            <div className="root">
                <CardActionArea className="rootArea">
                    {(data.web_img_64) && <CardMedia
                        className="media"
                        component="img"
                        alt="Product Picture"
                        height="140"
                        // image={data.web_img_64}
                        src={data.web_img_64}
                        title="Contemplative Reptile"
                    />}
                    <CardContent className="contentProd">
                        <Typography gutterBottom variant="button" component="h5">
                            {data.description}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" component="p">
                            {data.portionSize}
                        </Typography>
                    </CardContent>
                </CardActionArea>
                <CardActions className="actionsProd">
                    <p className="price">{Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(data.price)}</p>
                    <Button onClick={() => handleProductDetail(data)} variant="outlined" color="secondary">
                        Selecionar
                    </Button>
                </CardActions>
            </div>
        );
    }

    // function ProductItem({ data }) {
    //     return (
    //         <div className="divProductItems">
    //             <div className="productItems">
    //                 <div className="orderHorizontal">
    //                     <h5 style={{ width: "80%", textAlign: "justify" }}>{data.description}</h5>
    //                     <h5 style={{ width: "15%" }}>{Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(data.price)}</h5>
    //                 </div>
    //                 {(data.web_img_64 !== '') && <img className="imgProds" src={data.web_img_64} alt="food"/>}
    //                 {(data.web_img_64 === '') && <img className="imgProds" src={salada} alt="food"/>}
    //                 <textarea defaultValue={data.portionSize} className="portionComb" />
    //             </div>
    //         </div>
    //     );
    // };


    return (
        <div className="request-container">
            <div className="productHeader">
                <img className="logoProducts" src={provider.logo} alt="Logo" />
                <h4>Selecione um produto</h4>
            </div>
            <div className="productBoddy">

                {productsFilter.map(item => (
                    <ProductItem
                        data={item}
                        key={item.id} />
                ))}

            </div>
            <div className="containerFooter">
                <button onClick={() => handleBack()} className="productButton" type="button">Voltar</button>
            </div>
        </div>
    );
}
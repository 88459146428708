const initialState = [];

function ItemsReducer(state = initialState, action) {
    switch (action.type) {
        case 'ADD_ITEM':
            return [
                ...state,
                {
                    id: action.id,
                    idRequest: action.idRequest,
                    code: action.code,
                    description: action.description,
                    unity: action.unity,
                    amount: action.amount,
                    price: action.price,
                    total: action.total,
                    comments: action.comments,
                    combined: action.combined,
                    flavors: action.flavors
                }];
        case 'DEL_ITEM':
            let s = [...state];
            return s.filter(s => s.id !== action.id);
        case 'CLEAR_ITEM':
            return [];
        default:
            return state;
    }
};

export default ItemsReducer;
import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import Login from "./pages/Login";
import Register from "./pages/Register";
import RegisterConfirm from "./pages/RegisterConfirm";
import MyRequests from "./pages/MyRequests";
import Request from "./pages/Request";
import ForgotPassword from "./pages/ForgotPassword";
import Groups from "./pages/Groups";
import Products from "./pages/Products";
import ProductDetail from "./pages/ProductDetail";
import Provider from "./pages/Provider";
import Neighborhood from "./pages/Neighborhood";
import Checkout from "./pages/Checkout";
import LoadStorage from "./pages/LoadStorage";
import CombinedOrder from "./pages/CombinedOrder";
import CombinedEdge from "./pages/CombinedEdge";
import CombinedEdgeSingle from "./pages/CombideEdgeSingle";
// import CombinedAdd from './pages/CombinedAdd';
import CombinedCheckout from "./pages/CombinedCheckout";
import CombinedCheckoutSingle from "./pages/CombinedCheckoutSingle";
import MenuGroups from "./pages/MenuGroups";
import MenuProducts from "./pages/MenuProducts";

export default function Routes() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/provider/:idProvider" component={Provider} />
        <Route path="/" exact component={Login} />
        <Route path="/myrequests" component={MyRequests} />
        <Route path="/register" component={Register} />
        <Route path="/registerconfirm" component={RegisterConfirm} />
        <Route path="/request" component={Request} />
        <Route path="/forgotPassword" component={ForgotPassword} />
        <Route path="/groups" component={Groups} />
        <Route path="/products" component={Products} />
        <Route path="/productDetail" component={ProductDetail} />
        <Route path="/neighborhood" component={Neighborhood} />
        <Route path="/checkout" component={Checkout} />
        <Route path="/loadstorage" component={LoadStorage} />
        <Route path="/combinedOrder" component={CombinedOrder} />
        <Route path="/combinedEdge" component={CombinedEdge} />
        <Route path="/combinedCheckout" component={CombinedCheckout} />
        <Route path="/combinedCheckoutSingle" component={CombinedCheckoutSingle} />
        <Route path="/combinedEdgeSingle" component={CombinedEdgeSingle} />
        <Route path="/menugroups" component={MenuGroups} />
        <Route path="/menuproducts" component={MenuProducts} />
      </Switch>
    </BrowserRouter>
  );
}

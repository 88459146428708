import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { FiPlus, FiMinus } from 'react-icons/fi';

import './styles.css';

// import logoCustomer from '../../assets/logo.png';
import { useSelector, useDispatch } from 'react-redux';

export default function ProductDetail() {
    const [amount, setAmount] = useState('1');
    const [comments, setComments] = useState('');

    const provider = useSelector(state => state.provider);
    const request = useSelector(state => state.request);
    const items = useSelector(state => state.items);

    const history = useHistory();
    const dispatch = useDispatch();

    const itemSelected = useSelector(state => state.itemSelected);


    useEffect(() => {
        function loadStorage() {
            if (!provider.id) {
                dispatch({
                    type: 'ADD_STORAGE',
                    call: 'PRODUCTDETAIL'
                });
                history.push('/loadstorage');
            };
        }
        loadStorage();
    }, [
        dispatch,
        history,
        provider.id
    ]);


    function handleAdd() {
        dispatch({
            type: 'ADD_AMOUTSELECTED',
            amount: amount,
            total: (amount * itemSelected.price),
            comments: comments
        });
        const idItem = Math.floor(Math.random() * (1000 - 1) + 1);

        dispatch({
            type: 'ADD_ITEM',
            id: idItem,
            idRequest: request.id,
            code: itemSelected.code,
            description: itemSelected.description,
            unity: itemSelected.unity,
            amount: amount,
            price: itemSelected.price,
            total: (amount * itemSelected.price),
            comments: comments,
            combined: false,
            flavors: []
        });
        let storeItems = [];
        items.forEach((item) => {
            storeItems.push(JSON.stringify({
                'id': item.id,
                'idRequest': item.idRequest,
                'code': item.code,
                'description': item.description,
                'unity': item.unity,
                'amount': item.amount,
                'price': item.price,
                'total': item.total,
                'comments': item.comments,
                'combined': item.combined,
                'flavors': item.flavors
            }));
        });
        storeItems.push(JSON.stringify({
            'id': idItem,
            'idRequest': request.id,
            'code': itemSelected.code,
            'description': itemSelected.description,
            'unity': itemSelected.unity,
            'amount': amount,
            'price': itemSelected.price,
            'total': (amount * itemSelected.price),
            'comments': comments,
            'combined': false,
            'flavors': []
        }));
        localStorage.setItem('items', JSON.stringify(storeItems));

        // gerar o pedido e salvar no firebase       
        history.push('/request');
    }

    function handleBack() {
        history.push('/products');
    }

    function handleDecrease() {
        let a = amount;
        if (a > 1) {
            a = a - 1;
        }
        setAmount(a.toString());
    }

    function handleIncrease() {
        let a = parseFloat(amount);
        a = a + 1;
        setAmount(a.toString());
    }

    return (
        <div className="request-container">
            <div className="productDetailHeader">
                <img className="logoProductDetail" src={provider.logo} alt="Logo" />
                <div className="form">
                    <h2>{itemSelected.description}</h2>
                    <h3>Quantidade</h3>
                    <input maxLength={150} placeholder="1" value={amount} onChange={a => setAmount(a.target.value)} />
                    <div className="amountDiv">
                        <button onClick={() => handleDecrease()} className="decreaseButton">
                            <FiMinus size={16} color="#FFF" />
                        </button>
                        <button onClick={() => handleIncrease()} className="increaseButton">
                            <FiPlus size={16} color="#FFF" />
                        </button>
                    </div>
                    <h3>Observações</h3>
                    <input className="obs" onChange={o => setComments(o.target.value)} placeholder="Preferências, alergias, etc..." />
                </div>
            </div>
            <div className="containerFooter">
                <button onClick={() => handleAdd()} className="productDetailButton" type="button">Incluir</button>
                <button onClick={() => handleBack()} className="productDetailButton" type="button">Voltar</button>
            </div>
        </div>
    );
}
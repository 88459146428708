const initialState = [];

function NeighReducer(state = initialState, action) {
    switch (action.type) {
        case 'ADD_NEIGH':
            return [
                ...state,
                {
                    id: action.id,
                    code: action.code,
                    idProvider: action.idProvider,
                    name: action.name,
                    feeDelivery: action.feeDelivery,
                    city: action.city,
                    uf: action.uf,
                    ibge: action.ibge
                }];
        case 'CLEAR_NEIGH':
            return [];
        default:
            return state;
    }
};

export default NeighReducer;
const initialState = [];

function PaymentsReducer(state = initialState, action) {
    switch (action.type) {
        case 'ADD_PAYMENT':
            return [
                ...state,
                {
                    id: action.id,
                    formPayment: action.formPayment
                }];
        case 'CLEAR_PAYMENT':
            return [];
        default:
            return state;
    }
};

export default PaymentsReducer;
const initialState = {
    id: '',
    logo: '',
    email: '',
    password: '',
    cnpj: '',
    fantasy: '',
    feeDelivery: 0,
    feeDeliveryMode: '',
    ray: 0,
    open: 'N',
    singleEdge: 'S',
}

function ProviderReducer(state = initialState, action) {
    switch (action.type) {
        case 'ADD_PROVIDER':
            return {
                ...state,
                id: action.id,
                logo: action.logo,
                email: action.email,
                password: action.password,
                cnpj: action.cnpj,
                fantasy: action.fantasy,
                feeDelivery: action.feeDelivery,
                feeDeliveryMode: action.feeDeliveryMode,
                ray: action.ray,
                open: action.open,
                singleEdge: action.singleEdge,
            };
        case 'CLEAR_PROVIDER':
            return {
                id: '',
                logo: '',
                email: '',
                password: '',
                cnpj: '',
                fantasy: '',
                feeDelivery: 0,
                feeDeliveryMode: '',
                ray: 0,
                open: 'N',
                singleEdge: 'S',
            }
        default:
            return state;
    }
};

export default ProviderReducer;
const initialState = []

function RequestListReducer(state = initialState, action) {
    switch (action.type) {
        case 'ADD_REQUESTLIST':
            return [
                ...state,
                {
                    id: action.id,
                    idCustomer: action.idCustomer,
                    idProvider: action.idProvider,
                    dateRequest: action.dateRequest,
                    totalProducts: action.totalProducts,
                    feeDelivery: action.feeDelivery,
                    totalRequest: action.totalRequest,
                    status: action.status,
                    addressDifferent: action.addressDifferent,
                    comeGet: action.comeGet,
                    address: action.address,
                    number: action.number,
                    neigh: action.neigh,
                    idNeigh: action.idNeigh,
                    complement: action.complement,
                    formPayment: action.formPayment,
                    items: action.items,
                    ticket: action.ticket
                }];
        case 'CLEAR_REQUESTLIST':
            return [];
        default:
            return state;
    }
};

export default RequestListReducer;
const initialState = {
    id: '',
    idCustomer: '',
    idProvider: '',
    dateRequest: 0,
    totalProducts: 0,
    feeDelivery: 0,
    totalRequest: 0,
    status: "O",                    // S - enviado para entrega // P - produced // C - canceled // D - delivered 
    addressDifferent: false,
    comeGet: false,
    address: '',
    number: '',
    neigh: '',
    idNeigh: '',
    complement: '',
    formPayment: 'DINHEIRO'
}

function RequestReducer(state = initialState, action) {
    switch (action.type) {
        case 'ADD_REQUEST':
            return {
                ...state,
                id: action.id,
                idCustomer: action.idCustomer,
                idProvider: action.idProvider,
                dateRequest: action.dateRequest,
                totalProducts: action.totalProducts,
                feeDelivery: action.feeDelivery,
                totalRequest: action.totalRequest,
                status: action.status,
                addressDifferent: action.addressDifferent,
                comeGet: action.comeGet,
                address: action.address,
                number: action.number,
                neigh: action.neigh,
                idNeigh: action.idNeigh,
                complement: action.complement,
                formPayment: action.formPayment
            };
        case 'CLEAR_REQUEST':
            return {
                id: '',
                idCustomer: '',
                idProvider: '',
                dateRequest: 0,
                totalProducts: 0,
                feeDelivery: 0,
                totalRequest: 0,
                status: '',
                addressDifferent: false,
                comeGet: false,
                address: '',
                number: '',
                neigh: '',
                idNeigh: '',
                complement: '',
                formPayment: 'DINHEIRO'
            };
        case 'TPRODUCTS_REQUEST':
            return {
                ...state,
                totalProducts: action.totalProducts
            };
        case 'DIFFERENT_REQUEST':
            return {
                ...state,
                addressDifferent: action.addressDifferent
            };
        case 'COMEGET_REQUEST':
            return {
                ...state,
                comeGet: action.comeGet
            };
        case 'ADDRESS_REQUEST':
            return {
                ...state,
                address: action.address
            };
        case 'NUMBER_REQUEST':
            return {
                ...state,
                number: action.number
            };
        case 'NEIGH_REQUEST':
            return {
                ...state,
                neigh: action.neigh,
                idNeigh: action.idNeigh
            };
        case 'COMPLEMENT_REQUEST':
            return {
                ...state,
                complement: action.complement
            }
        case 'TOTAL_REQUEST':
            return {
                ...state,
                feeDelivery: action.feeDelivery,
                totalRequest: action.totalRequest
            };
        case 'FORMPAYMENT_REQUEST':
            return {
                ...state,
                formPayment: action.formPayment
            }
        default:
            return state;
    }
};

export default RequestReducer;
import React from 'react';
import { useHistory } from 'react-router-dom';

import './styles.css';

// import logoCustomer from '../../assets/logo.png';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';
import salada from '../../assets/salada.jpg'

// import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';



export default function CombinedOrder() {

    const history = useHistory();

    const dispatch = useDispatch();

    const provider = useSelector(state => state.provider);
    const products = useSelector(state => state.products);
    const productsFilter = useSelector(state => state.productsFilter);
    const groupSelected = useSelector(state => state.groupSelected);
    const flavors = useSelector(state => state.flavors);
    const request = useSelector(state => state.request);

    useEffect(() => {
        function loadStorage() {
            if (!provider.id) {
                dispatch({
                    type: 'ADD_STORAGE',
                    call: 'COMBINEDORDER'
                });
                history.push('/loadstorage');
            };
        }
        loadStorage();
    }, [dispatch, history, provider.id]);

    useEffect(() => {
        function filterProducts() {
            dispatch({
                type: 'CLEAR_PRODUCTFILTER',
            });
            products.forEach((item) => {
                if (item.subgroup === groupSelected.group) {
                    dispatch({
                        type: 'ADD_PRODUCTFILTER',
                        id: item.id,
                        idProvider: item.idProvider,
                        code: item.code,
                        description: item.description,
                        unity: item.unity,
                        price: item.price,
                        group: item.group,
                        subgroup: item.subgroup,
                        active: item.active,
                        fractioned: item.fractioned,
                        fractions: item.fractions,
                        edge: item.edge,
                        additional: item.additional,
                        portionSize: item.portionSize
                    });
                };
            });
        }
        filterProducts();
    }, [dispatch, groupSelected.group, products]);

    function handleProductSelect(data) {
        if (provider.singleEdge === 'N') {
            dispatch({
            type: 'ADD_FLAVORSEL',
            id: data.id,
            idRequest: data.idRequest,
            code: data.code,
            description: data.description,
            unity: data.unity,
            price: data.price,
            });
            localStorage.setItem('flavorSelected', JSON.stringify({
                'id': data.id,
                'idRequest': data.idRequest,
                'code': data.code,
                'description': data.description,
                'unity': data.unity,
                'price': data.price,
            }));

            history.push('/combinedEdge');
        } else {
            let flavorsStorage = [];
            let currentList = flavors;
            let amountFlavors = flavors.length + 1;
            dispatch({
                type: 'CLEAR_FLAVORS',
            });
    
            if (currentList) {
                currentList.forEach((item) => {
               
                    dispatch({
                        type: 'ADD_FLAVORS',
                        id: item.id,
                        idRequest: item.idRequest,
                        code: item.code,
                        description: item.description,
                        unity: item.unity,
                        amount: (1 / amountFlavors),
                        price: item.price,
                        totalFlavor: (item.price / amountFlavors),
                        codeEdge: '',
                        edge: '',
                        priceEdge: 0,
                        totalEdge: 0,
                        total: (item.price / amountFlavors),
                        comments: ''
                    });
                    flavorsStorage.push({
                        id: item.id,
                        idRequest: item.idRequest,
                        code: item.code,
                        description: item.description,
                        unity: item.unity,
                        amount: (1 / amountFlavors),
                        price: item.price,
                        totalFlavor: (item.price / amountFlavors),
                        codeEdge: '',
                        edge: '',
                        priceEdge: 0,
                        totalEdge: 0,
                        total: (item.price / amountFlavors),
                        comments: ''
                    })
                });   
            }
            // let totalAdd = 0;
            // additionalSel.forEach((i) => {
            //     totalAdd = totalAdd + (i.price / amountFlavors);
            // });
            dispatch({
                type: 'ADD_FLAVORS',
                id: data.id,
                idRequest: request.id,
                code: data.code,
                description: data.description,
                unity: data.unity,
                amount: (1 / amountFlavors),
                price: data.price,
                totalFlavor: (data.price / amountFlavors),
                codeEdge: '',
                edge: '',
                priceEdge: 0,
                totalEdge: 0,
                total: (data.price / amountFlavors),
                comments: ''
            });
            flavorsStorage.push({
                id: data.id,
                idRequest: request.id,
                code: data.code,
                description: data.description,
                unity: data.unity,
                amount: (1 / amountFlavors),
                price: data.price,
                totalFlavor: (data.price / amountFlavors),
                codeEdge: '',
                edge: '',
                priceEdge: 0,
                totalEdge: 0,
                total: (data.price / amountFlavors),
                comments: ''
            });
            localStorage.setItem('flavors', JSON.stringify(flavorsStorage));
            history.push('/combinedCheckoutSingle');
        }
        // history.push('/combined')
    };

    function handleCancel() {
        history.push('/groups');
    };

    function ProductItem({ data }) {
        return (
            <div className="root">
                <CardActionArea className="rootArea">
                    {(data.web_img_64 === '') && <CardMedia
                        className="media"
                        component="img"
                        alt="Contemplative Reptile"
                        height="140"
                        image={salada}
                        title="Contemplative Reptile"
                    />}
                    {(!data.web_img_64 === '') && <CardMedia
                        className="media"
                        component="img"
                        alt="Contemplative Reptile"
                        height="140"
                        image={data.web_img_64}
                        title="Contemplative Reptile"
                    />}
                    <CardContent className="contentProd">
                        <Typography gutterBottom variant="button" component="h5">
                            {data.description}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" component="p">
                            {data.portionSize}
                        </Typography>
                    </CardContent>
                </CardActionArea>
                <CardActions className="actionsProd">
                    <p className="price">{Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(data.price)}</p>
                    <Button onClick={() => handleProductSelect(data)} variant="outlined" color="secondary">
                        Selecionar
                    </Button>
                </CardActions>
            </div>
        );
    }


    // function ProductItem({ data }) {
    //     return (
    //         <div className="divCombItem">
    //             <button onClick={() => handleProductDetail(data)} className="combItems">
    //                 <div className="orderHorizontal">
    //                     <h5 style={{width: "80%", textAlign: "justify"}}>{data.description}</h5>
    //                     <h5 style={{width: "15%"}}>{Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(data.price)}</h5>
    //                 </div>
    //                 <textarea defaultValue={data.portionSize} className="portionComb"/>
    //             </button>
    //         </div>
    //     );
    // };


    return (
        <div className="combine-container">
            <div className="combineHeader">
                <img className="logoCombine" src={provider.logo} alt="Logo" />
                <div className="orientation">
                    <h5 style={{ marginTop: '5px' }}>Escolha até {groupSelected.fractions} opções</h5>
                    <h5 style={{ marginTop: '5px' }}>{groupSelected.group}</h5>
                </div>
            </div>
            <div className="combineBoddy">

                {productsFilter.map(item => (
                    <ProductItem
                        data={item}
                        key={item.id} />
                ))}

            </div>
            <div className="combineFooter">
                {/* <button onClick={() => handleConfirm()} className="combineButton" type="button">Confirmar produto</button> */}
                <button onClick={() => handleCancel()} className="combineButton" type="button">Voltar</button>
            </div>
        </div>
    );
}
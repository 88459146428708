import React, { useState, useEffect } from 'react';
import firebase from '../../services/firebaseConfig';
import { Link, useHistory } from 'react-router-dom';
import { FiLogIn, FiMenu, FiAtSign } from 'react-icons/fi';

import './styles.css';

// import logoCustomer from '../../assets/logo.png';
import { useDispatch, useSelector } from 'react-redux';

export default function Login() {

    const history = useHistory();
    const dispatch = useDispatch();
    const provider = useSelector(state => state.provider);

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    useEffect(() => {
        function loadStorage() {
            if (!provider.id) {
                dispatch({
                    type: 'ADD_STORAGE',
                    call: 'LOGIN'
                });
                history.push('/loadstorage');
            };
        }
        loadStorage();
    }, [
        dispatch,
        history,
        provider.id
    ]);

    function handleLogin(e) {
        e.preventDefault(e);
        localStorage.removeItem('gselected');
        localStorage.removeItem('request');
        localStorage.removeItem('flavorSelected');
        localStorage.removeItem('iSelected');
        if (provider.open === 'S') {
        // console.log(groups);
        firebase.auth().signInWithEmailAndPassword(email, password)
            .then(async () => {
                let uid = firebase.auth().currentUser.uid;
                // localStorage.setItem('email', email);
                // localStorage.setItem('password', password);
                firebase.firestore().collection("customers").where("id", "==", uid).get()
                    .then((result) => {
                        result.forEach((item) => {
                            dispatch({
                                type: 'ADD_USER',
                                id: uid,
                                email: email,
                                password: password,
                                name: item.data().name,
                                cpf: item.data().cpf,
                                zipcode: item.data().zipcode,
                                address: item.data().address,
                                number: item.data().number,
                                neigh: item.data().neigh,
                                idNeigh: item.data().idNeigh,
                                feeDelivery: item.data().feeDelivery,
                                complement: item.data().complement,
                                city: item.data().city,
                                uf: item.data().uf,
                                ibge: item.data().ibge,
                                whats: item.data().whats,
                                comments: item.data().comments,
                                idProvider: item.data().idProvider
                            });
                            localStorage.setItem('user', JSON.stringify({
                                'id': uid,
                                'email': email,
                                'password': password,
                                'name': item.data().name,
                                'cpf': item.data().cpf,
                                'zipcode': item.data().zipcode,
                                'address': item.data().address,
                                'number': item.data().number,
                                'neigh': item.data().neigh,
                                'idNeigh': item.data().idNeigh,
                                'feeDelivery': item.data().feeDelivery,
                                'complement': item.data().complement,
                                'city': item.data().city,
                                'uf': item.data().uf,
                                'ibge': item.data().ibge,
                                'whats': item.data().whats,
                                'comments': item.data().comments,
                                'idProvider': item.data().idProvider
                            }));
                        });
                        history.push('/myrequests');
                    });
                // localStorage.setItem('id', uid)
            })
            .catch((erro) => {
                if (erro.code === 'auth/wrong-password') {
                    alert('Senha errada!')
                } else if (erro.code === 'auth/user-not-found') {
                    alert('Usuário não encontrado!')
                } else {
                    alert(erro.code);
                }
            });
        } else {
            alert('Estabelecimento fechado!');
        }
    }

    return (
        <div className="login-container">
            <section className="sectionForm">
                <img className="imgLogin" src={provider.logo} alt="Logo" />

                <form onSubmit={handleLogin}>
                    <div className="areaLogin">
                        <p className="facaLogin">Faça seu login</p>
                        <Link className="cardapioLink" to="/menugroups">
                            <FiMenu size={16} color="#e02041"/>
                            Olhar o cardápio
                        </Link>
                    </div>
                    <input
                        type="email"
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                        placeholder="E-mail"
                    />
                    <input
                        type="password"
                        value={password}
                        onChange={p => setPassword(p.target.value)}
                        placeholder="Senha"
                    />
                    <button className="button" type="submit">Entrar</button>

                    <Link className="backlink" to="/register">
                        <FiLogIn size={16} color="#E02041" />
                        Não tenho cadastro.
                    </Link>
                    <Link className="backlink" to="/forgotPassword">
                        <FiAtSign size={16} color="#E02041" />
                        Esqueci minha senha.
                    </Link>
                    {(provider.open === 'S') && <p style={{color: "green", fontWeight: "bold"}}>Restaurante aberto</p>}
                    {(provider.open === 'N') && <p style={{color: "red", fontWeight: "bold"}}>Restaurante fechado</p>}
                </form>
            </section>
        </div>
    );
}
const initialState = {
    id: '',
    idRequest: '',
    code: '',
    description: '',
    unity: '',
    amount: 1,
    price: 0,
    total: 0,
    comments: 0
};

function ItemSelectedReducer(state = initialState, action) {
    switch (action.type) {
        case 'ADD_ITEMSELECTED':
            return {
                ...state,
                id: action.id,
                idRequest: action.idRequest,
                code: action.code,
                description: action.description,
                unity: action.unity,
                price: action.price,
            };
        case 'CLEAR_ITEMSELECTED':
            return {
                id: '',
                idRequest: '',
                code: '',
                description: '',
                unity: '',
                amount: 1,
                price: 0,
                total: 0,
                comments: 0
            };
        case 'ADD_AMOUTSELECTED':
            return {
                ...state,
                amount: action.amount,
                total: action.total,
                comments: action.comments
            };
        // case 'ADD_COMMENTSELECTED':
        //     return {
        //         ...state,
        //         comments: action.comments
        //     }
        default:
            return state;
    }
};

export default ItemSelectedReducer;
const initialState = [];

function GroupsReducer(state = initialState, action) {
    switch (action.type) {
        case 'ADD_GROUP':
            return [
                ...state,
                {
                    id: action.id,
                    group: action.group,
                    fractioned: action.fractioned,
                    fractions: action.fractions
                }];
        case 'DEL_GROUP':
            let s = [...state];
            return s.filter(s => s.id !== action.id);
        case 'CLEAR_GROUP':
            return [];
        default:
            return state;
    }
};

export default GroupsReducer;
const initialState = {
    id: '',
    group: '',
    fractioned: '',
    fractions: 1
};

function GroupSelectedReducer(state = initialState, action) {
    switch (action.type) {
        case 'ADD_GROUPSELECTED':
            return {
                ...state,
                id: action.id,
                group: action.group,
                fractioned: action.fractioned,
                fractions: action.fractions
            };
        case 'CLEAR_GROUPSELECTED':
            return {
                id: '',
                group: '' ,
                fractioned: '',
                fractions: 1
            };
        default:
            return state;
    }
};

export default GroupSelectedReducer;
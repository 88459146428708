const initialState = [];

function ProductsFilterReducer(state = initialState, action) {
    switch (action.type) {
        case 'ADD_PRODUCTFILTER':
            return [
                ...state,
                {
                    id: action.id,
                    idProvider: action.idProvider,
                    code: action.code,
                    description: action.description,
                    unity: action.unity,
                    price: action.price,
                    group: action.group,
                    subgroup: action.subgroup,
                    active: action.active,
                    fractioned: action.fractioned,
                    fractions: action.fractions,
                    edge: action.edge,
                    additional: action.additional,
                    portionSize: action.portionSize,
                    web_img_64: action.web_img_64
                }];
        case 'CLEAR_PRODUCTFILTER':
            return [];
        default:
            return state;
    }
};

export default ProductsFilterReducer;
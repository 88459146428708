import React, { useEffect, useState, useCallback } from "react";
import firebase from "../../services/firebaseConfig";
import { useHistory } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";

import "./styles.css";

import { useDispatch, useSelector } from "react-redux";

export default function LoadStorage() {
  const history = useHistory();
  const callStorage = useSelector((state) => state.callStorage);
  // const products = useSelector(state => state.products);
  const dispatch = useDispatch();

  const [working, setWorking] = useState(false);

  const loadProducts = useCallback((id) => {
    let storeGroupSelected = JSON.parse(localStorage.getItem("gselected"));
    firebase
      .firestore()
      .collection("products")
      .orderBy("description")
      .where("idProvider", "==", id)
      .where("active", "==", "S")
      .get()
      .then((result) => {
        // console.log('chamou o load products');
        dispatch({
          type: "CLEAR_PRODUCTFILTER",
        });
        dispatch({
          type: "CLEAR_PRODUCT",
        });
        let allGroups = [];
        result.forEach((item) => {
          // console.log(item.data());
          dispatch({
            type: "ADD_PRODUCT",
            id: item.data().id,
            idProvider: id,
            code: item.data().code,
            description: item.data().description,
            unity: item.data().unity,
            price: item.data().price,
            group: item.data().group,
            subgroup: item.data().subgroup,
            active: item.data().active,
            fractioned: item.data().fractioned,
            fractions: item.data().fractions,
            edge: item.data().edge,
            additional: item.data().additional,
            portionSize: item.data().portionSize,
            web_img_64: item.data().web_img_64,
          });
          if (storeGroupSelected) {
            if (item.subgroup === storeGroupSelected.group) {
              // console.log('passou por aqui');
              dispatch({
                type: "ADD_PRODUCTFILTER",
                id: item.data().id,
                idProvider: id,
                code: item.data().code,
                description: item.data().description,
                unity: item.data().unity,
                price: item.data().price,
                group: item.data().group,
                subgroup: item.data().subgroup,
                active: item.data().active,
                fractioned: item.data().fractioned,
                fractions: item.data().fractions,
                edge: item.data().edge,
                additional: item.data().additional,
                portionSize: item.data().portionSize,
                web_img_64: item.data().web_img_64,
              });
            }
          }

          // console.log(item.data());
          let insert = true;
          allGroups.forEach((g) => {
            if (g.group === item.data().subgroup) {
              insert = false;
            }
          });
          // console.log(item.subgroup);
          if (insert === true) {
            allGroups.push({
              id: item.data().id,
              group: item.data().subgroup,
              fractioned: item.data().fractioned,
              fractions: item.data().fractions,
            });
          }
        });
        dispatch({
          type: "CLEAR_GROUP",
        });
        allGroups.forEach((item) => {
          dispatch({
            type: "ADD_GROUP",
            id: item.id,
            group: item.group,
            fractioned: item.fractioned,
            fractions: item.fractions,
          });
        });
      });
  }, [
      dispatch
  ]);

  const loadFormPayment = useCallback((id) => {
    firebase
      .firestore()
      .collection("formPayment")
      .orderBy("formPayment")
      .where("idProvider", "==", id)
      .get()
      .then((result) => {
        dispatch({
          type: "CLEAR_PAYMENT",
        });
        result.forEach((item) => {
          dispatch({
            type: "ADD_PAYMENT",
            id: item.data().id,
            formPayment: item.data().formPayment,
          });
        });
      });
  }, [
      dispatch
  ]);

  const loadNeighborhood = useCallback((id) => {
    setWorking(true);
    dispatch({ type: "CLEAR_NEIGH" });
    firebase
      .firestore()
      .collection("neighborhood")
      .orderBy("name")
      .where("idProvider", "==", id)
      .where("active", "==", "S")
      .get()
      .then((result) => {
        result.forEach((item) => {
          dispatch({
            type: "ADD_NEIGH",
            id: item.data().id,
            code: item.data().code,
            idProvider: item.data().idProvider,
            name: item.data().name,
            feeDelivery: item.data().feeDelivery,
            city: item.data().city,
            uf: item.data().uf,
            ibge: item.data().ibge,
          });
        });
        setWorking(false);
      })
      .catch((erro) => {
        console.log(erro);
      });
  }, [dispatch]);


  const loadProvider = useCallback((callback) => {
    // console.log('chamou providers');
    let storeProvider = JSON.parse(localStorage.getItem("provider"));
    // setIdProvider(storeProvider.id);
    loadProducts(storeProvider.id);
    loadFormPayment(storeProvider.id);
    loadNeighborhood(storeProvider.id);
    if (storeProvider) {
      firebase
        .firestore()
        .collection("providers")
        .orderBy("name")
        .where("id", "==", storeProvider.id)
        .get()
        .then((result) => {
          result.forEach((item) => {
            dispatch({
              type: "ADD_PROVIDER",
              id: storeProvider.id,
              logo: item.data().logo,
              email: item.data().email,
              password: item.data().password,
              cnpj: item.data().cnpj,
              fantasy: item.data().fantasy,
              feeDelivery: item.data().feeDelivery,
              feeDeliveryMode: item.data().feeDeliveryMode,
              ray: item.data().ray,
              open: item.data().open,
            });
            localStorage.setItem(
              "provider",
              JSON.stringify({
                id: storeProvider.id,
                logo: item.data().logo,
                email: item.data().email,
                password: item.data().password,
                cnpj: item.data().cnpj,
                fantasy: item.data().fantasy,
                feeDelivery: item.data().feeDelivery,
                feeDeliveryMode: item.data().feeDeliveryMode,
                ray: item.data().ray,
                open: item.data().open,
              })
            );
          });
          callback(true);
        });
    }
  }, [
      dispatch,
      loadFormPayment,
      loadNeighborhood,
      loadProducts
  ]);

  const loadUser = useCallback((callback) => {
    // console.log('chamou user');
    let storeUser = JSON.parse(localStorage.getItem("user"));
    if (storeUser) {
      dispatch({
        type: "ADD_USER",
        id: storeUser.id,
        email: storeUser.email,
        password: storeUser.password,
        name: storeUser.name,
        cpf: storeUser.cpf,
        zipcode: storeUser.zipcode,
        address: storeUser.address,
        number: storeUser.number,
        neigh: storeUser.neigh,
        idNeigh: storeUser.idNeigh,
        feeDelivery: parseFloat(storeUser.feeDelivery),
        complement: storeUser.complement,
        city: storeUser.city,
        ibge: storeUser.ibge,
        uf: storeUser.uf,
        whats: storeUser.whats,
        comments: storeUser.comments,
        idProvider: storeUser.idProvider,
      });
      callback(true);
    } else {
      callback(false);
    }
  }, [dispatch]);

  const loadRequest = useCallback((callback) => {
    // console.log('chamou request');
    let storeRequest = JSON.parse(localStorage.getItem("request"));
    if (storeRequest) {
      // console.log('chamou loadstorage' + storeRequest.addressDifferent.toString());
      dispatch({
        type: "ADD_REQUEST",
        id: storeRequest.id,
        idCustomer: storeRequest.idCustomer,
        idProvider: storeRequest.idProvider,
        dateRequest: parseFloat(storeRequest.dateRequest),
        totalProducts: parseFloat(storeRequest.totalProducts),
        feeDelivery: parseFloat(storeRequest.feeDelivery),
        totalRequest: parseFloat(storeRequest.totalRequest),
        status: storeRequest.status,
        addressDifferent: storeRequest.addressDifferent,
        comeGet: storeRequest.comeGet,
        address: storeRequest.address,
        number: storeRequest.number,
        neigh: storeRequest.neigh,
        idNeigh: storeRequest.idNeigh,
        complement: storeRequest.complement,
        formPayment: storeRequest.formPayment,
      });
      callback(true);
    } else {
      callback(false);
    }
  }, [dispatch]);

  const loadFlavors = useCallback((callback) => {
    let storeFlavors = JSON.parse(localStorage.getItem("flavors"));
    if (storeFlavors) {
      storeFlavors.forEach((i) => {
        dispatch({
          type: "ADD_FLAVORS",
          id: i.id,
          idRequest: i.idRequest,
          code: i.code,
          description: i.description,
          unity: i.unity,
          amount: i.amount,
          price: i.price,
          totalFlavor: i.totalFlavor,
          codeEdge: i.codeEdge,
          edge: i.edge,
          priceEdge: i.priceEdge,
          totalEdge: i.totalEdge,
          total: i.total,
          comments: i.comments,
          additionals: i.additionals,
        });
      });
      callback(true);
    } else {
      callback(false);
    }
  }, [dispatch]);

  const loadItems = useCallback((callback) => {
    // console.log('chamou items');
    let storeItems = JSON.parse(localStorage.getItem("items"));
    if (storeItems) {
      storeItems.forEach((i) => {
        let item = JSON.parse(i);
        dispatch({
          type: "ADD_ITEM",
          id: item.id,
          idRequest: item.idRequest,
          code: item.code,
          description: item.description,
          unity: item.unity,
          amount: item.amount,
          price: item.price,
          total: item.total,
          comments: item.comments,
          combined: item.combined,
          flavors: item.flavors,
        });
      });
      callback(true);
    } else {
      callback(false);
    }
  }, [dispatch]);

  const loadGroupSelected = useCallback((callback) => {
    // console.log('chamou groupselected');
    let storeGroupSelected = JSON.parse(localStorage.getItem("gselected"));
    if (storeGroupSelected) {
      dispatch({
        type: "ADD_GROUPSELECTED",
        id: storeGroupSelected.id,
        group: storeGroupSelected.group,
      });
      callback(true);
    } else {
      callback(false);
    }
  }, [dispatch]);

  const loadFlavorSelected = useCallback((callback) => {
    // console.log('chamou groupselected');
    let flavorSelected = JSON.parse(localStorage.getItem("flavorSelected"));
    if (flavorSelected) {
      dispatch({
        type: "ADD_FLAVORSEL",
        id: flavorSelected.id,
        idRequest: flavorSelected.idRequest,
        code: flavorSelected.code,
        description: flavorSelected.description,
        unity: flavorSelected.unity,
        price: flavorSelected.price,
      });
      dispatch({
        type: "ADD_FLAVORSELEDGE",
        codeEdge: flavorSelected.codeEdge,
        edge: flavorSelected.edge,
        priceEdge: parseFloat(flavorSelected.priceEdge),
        comments: flavorSelected.comments,
      });
      // console.log('flavorSelected storage' + flavorSelected.edge);
      callback(true);
    } else {
      callback(false);
    }
  }, [dispatch]);

  const loadItemSelected = useCallback((callback) => {
    let storeItemSelected = JSON.parse(localStorage.getItem("iselected"));
    if (storeItemSelected) {
      dispatch({
        type: "ADD_ITEMSELECTED",
        id: storeItemSelected.id,
        idRequest: storeItemSelected.idRequest,
        code: storeItemSelected.code,
        description: storeItemSelected.description,
        unity: storeItemSelected.unity,
        amount: storeItemSelected.amount,
        price: storeItemSelected.price,
        total: storeItemSelected.total,
        comments: storeItemSelected.comments,
      });
      callback(true);
    } else {
      callback(false);
    }
  }, [dispatch]);

  const handleNavigation = useCallback(() => {
    console.log("chamou combined order 2");
    console.log(callStorage.call);
    if (callStorage.call === "LOGIN") {
      history.push("/");
    } else if (callStorage.call === "MYREQUESTS") {
      history.push("/myrequests");
    } else if (callStorage.call === "REQUEST") {
      history.push("/request");
    } else if (callStorage.call === "GROUPS") {
      history.push("/groups");
    } else if (callStorage.call === "PRODUCTS") {
      history.push("/products");
    } else if (callStorage.call === "COMBINEDORDER") {
      history.push("/combinedOrder");
    } else if (callStorage.call === "COMBINEDEDGE") {
      history.push("/combinedEdge");
    } else if (callStorage.call === "COMBINEDADD") {
      history.push("/combinedAdd");
    } else if (callStorage.call === "COMBINEDCHECKOUT") {
      history.push("/combinedCheckout");
    } else if (callStorage.call === "PRODUCTDETAIL") {
      history.push("/productDetail");
    } else if (callStorage.call === "CHECKOUT") {
      history.push("/checkout");
    } else if (callStorage.call === "MENUGROUPS") {
      history.push("/menugroups");
    } else if (callStorage.call === "MENUPRODUCTS") {
      history.push("/menuproducts");
    } else {
      history.push("/");
    }
  }, [
      callStorage.call,
      history
    ]);

  useEffect(() => {
    function loadStorage() {
      // setWorking(true);
      loadProvider(function (ok) {
        loadUser(function (okUser) {
          loadRequest(function (okUser) {
            loadItems(function (okRequest) {
              loadGroupSelected(function (okItems) {
                loadFlavorSelected(function (okGroup) {
                  loadFlavors(function (okFlavorSelected) {
                    loadItemSelected(function (okFlavors) {
                      // setWorking(false);
                      handleNavigation(okFlavors);
                    });
                  });
                });
              });
            });
          });
        });
      });
    }
    loadStorage();
  }, [
    handleNavigation,
    loadFlavorSelected,
    loadFlavors,
    loadGroupSelected,
    loadItemSelected,
    loadItems,
    loadProvider,
    loadRequest,
    loadUser,
  ]); //eslint ignore

  return (
    <div className="login-container">
      <section className="sectionForm">
        <h1>Carregando informações</h1>
        {working && (
          <div className="divActivity">
            <CircularProgress />
            {/* <CircularProgress color="secondary" /> */}
          </div>
        )}
      </section>
    </div>
  );
}
